.login__form {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 90% !important;
    margin: auto;
    padding: 2rem 2rem 1rem 2rem !important; /* spacing out evenly based on parent and container content */
}

.login__element,
.login__button,
.login__remember {
    display: flex;
    flex: 100%;
    width: 100%;
    max-width: 328px;
}

#login__button {   
    padding: .5rem;
    justify-content: center;
    font-size: var(--fs-500);
    font-weight: var(--fw-500);
    letter-spacing: var(--ls-300);
}



/*----------------------------------------------------------------------------*/
/** Stay Logged In element */

.login__remember{
    display: flex;
    justify-content: center;
    gap: 2rem;
    /* padding-bottom: 1rem; */
    font-size: var(--fs-500);
    color: var(--clr-fnt-700);
    letter-spacing: var(--ls-100);
    align-items: center;
    
}

.login__remember input[type='checkbox']{
    display: none;
    display: hidden;        /* hidden because lock SVG is UI  */
    pointer-events: none;   /* disabled because parent has on-click event */
}

.login__remember_checked{
    color: var(--clr-fnt-900);
    display: flex;
    justify-content: center;
}

.login__remember_checked svg{
    fill: var(--clr-fnt-1000)
}

.login__remember_checked::before {
    text-align: right;
    justify-content: right;
    /* content: 'test'; */
    width: 20%;
    
}



/*----------------------------------------------------------------------------*/
/** Need Help Logging in / Reset Auth Section */

.login__form__loginReset{
    margin-top: 3rem;
    text-decoration: none; /* keeps from nav-link auto-adding underline */
    position: relative;
    letter-spacing: inherit;
    color: var(--clr-fnt-700);
    font-size: var(--fs-300);
    
    transition: ease-in-out 200ms;
}

.login__form__loginReset:hover{
    color: var(--clr-fnt-1000);

}


.login__form__loginReset::before,
.login__form__loginReset:hover::before,
.login__form__loginReset::after,
.login__form__loginReset:hover::after{
    letter-spacing: inherit;
    color: transparent; /* hiding the text */
    white-space: nowrap;
    overflow: hidden;
    content: 'Need Help Logging In?'; /* content so appears*/

    pointer-events: none;

    /*width: 100% !important; /* start visible */
    /*left: 0%;   /* to grow from left when full*/

    --leftStartBefore: 0%;
    --underlineWidthBefore: 100% !important;
    
    --leftStartAfter: 50%;
    --underlineWidthAfter: 10% !important;
}

.login__form__loginReset::before{
    position: absolute; /* absolute to it's parent */
    /*left: 2.5%;   /* making left 5% to make the underline start from the left */
    
    left: var(--leftStartBefore);
    width: var(--underlineWidthBefore); /* not full width */
    bottom: -2px;  /* push below text */
    
    text-decoration: underline var(--clr-fnt-200) 1px; /* and underline var and color */
    transition: ease-in-out 200ms;
}

.login__form__loginReset:hover::before{
    left: 100%;
    width: 0%;
}


.login__form__loginReset::after{
    /* Default config hidden */
    
    position: absolute; /* to make it go below actual text */
    content: 'Need Help Logging In?'; /* Required so args match*/
    
    width: var(--underlineWidthAfter); /* starting hidden */
    left: var(--leftStartAfter); /* to grow from center when hidden */
    
    bottom: -2px;  /* push below text */
    color: transparent; /* hiding the text */
    
    opacity: 0;             /* so fades in */
    overflow: hidden;   /* prevent expanding */
    white-space: nowrap;    /* prevent wrapping */
    text-decoration: underline transparent 1px; /* and underline var and color */
    
    transition: left 50ms, color ease-in-out 200ms, width ease-in-out 300ms, opacity 500ms;
}


.login__form__loginReset:hover::after{
    /* When Hovered  */
    
    width: 100% !important; /* not full width */
    left: 0%;
    opacity: 1;
    color: transparent; /* hiding the text */
    content: 'Need Help Logging In?'; /* content so appears*/
    text-decoration: underline var(--clr-accent) 1px; /* and underline var and color */
    
}