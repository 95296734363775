/*------------------------------------------------------------------------------
Business Name once entered account id */

.account-name
{ /* Used to show API results of business name */
    justify-content: center;
    width: 100%; /* Fit Full container */
    max-width: 329px; /* 1 more Pixel than the inputs to account for Borders  Never get bigger than this, but can get smaller for mobile */
    text-align: center;
    /* background-color: rgba(255,255,255,.3); */
    border-radius: .25rem .25rem 0 0;
    /* border: 1px solid rgba(0,0,0, .3); */
    /* box-shadow: var(--box-shadow-default); */
    padding: .5rem;
    margin: auto;
    margin-bottom: 10px;
    line-height: 2rem;
    color: var(--header-font-color-dark);
    
    
}
.account-name h3{
    font-size: 2rem;
    padding: 0;
    margin: 0;
    font-weight: 400;
}

.account__navigation{
    position: absolute;
    right: 0;
    top: .25rem;
    z-index: 5;
}

.account__navigation .navigation__link {
    position: relative;
    border: 1px solid transparent;
    border-radius: var(--border-radius-default);
}
.account__navigation .navigation__link:hover {
    border: 1px solid rgba(255,255,255, .2);
    border-radius: var(--border-radius-default);
}