/** Normalizing makes it possible to ensure all Browsers render content the same.
 *
 * There is some pretty heavy-handed stuff here that is used to remove wildcards
 * and guarantee complete control of styling. 
 * 
 *
*/


/* HTML ELEMENTS */
*, *::after, *::before 
{ 
    /** Prevent padding overflow, removing base margins & padding, and unify font. 
    */
    

    /* Keeps elements from overflowing containers by restricting parent padding */
    box-sizing: border-box;
    
    /* Remove ALL default padding and margins for EVERYTHING so not wildcards */
    margin: 0;
    padding: 0;
    
    /* Ensures fonts are consistent and easy to manage */
    font: inherit;
}


ul[role="list"], ol[role="list"] 
{ 
    /** Remove List Styling on all UL / OL elements with a list role.
     *   Suggests default styling will be removed.
    */
    list-style: none;
}

a:not([class]) 
{   /* a{ text-decoration: none; } */
    text-decoration-skip-ink: auto; 
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}


/* REACT AND NEXT JS */
#root, #__next {
  isolation: isolate;
}
