#account-selector__container{
    position: fixed;
    padding: .25rem 1rem;
    /* margin: auto; */
    height: fit-content;    /* Make sure it always fits content but is not bigger */
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: .5rem;   
    
    background-color: var(--navigation__aside-bg);
    
    /* To show visual separation between two menus */
    border-left: 1px solid rgba(0,0,0,.2);

    flex-wrap: nowrap;
    
    
    /* To put at the bottom of the page above the footer. */
    bottom: var(--footer-height);
    /* To put at the TOP of the page */
    /* top: var(--header-height); */
    /* to prevent scrollbar from appearing for some reason */ 
    overflow: hidden; 
    /* To fit inside container accounting for aside nav and scrollbar  */
    width: calc(100% - var(--navigation__aside-width) - 1rem);
    /* To account for scrollbar */
    right: 1rem; 
}


