/*------------------------------------------------------------------------------
BANNER COMPONENT 
    
    - The BANNER is the error-message banner that slides in and out
*/

:root {
    /* DURATIONS*/
    --banner-ease-in: 300ms;
    --banner-ease-out: 800ms;
    
    
    /*THEMING*/
    --banner__bg_color_success: var(_success);
    --banner__font_color_success: #FEFEFE;
    
    --banner__bg_color_danger: var(--danger);
    --banner__font_color_error: #FEFEFE;

    --banner__bg_color--warning: var(--warning);
    --banner__font_color--warning: #FEFEFE;

    --banner__bg_color_info: #2196F3;
    --banner__font_color_info: #FEFEFE;

    --banner__bg_color_default: var(--general);
    --banner__font_color_default: var(--clr-fnt-1000);


    --banner__bg_color:     var(--banner__bg_color_default);
    --banner__font_color:   var(--banner__font_color_default);
    
}

.banner-parent
{ /* Set Parent banner wrapper within the Banner component as grid with basic design for easy sub-content mgmt*/
    
    /* Hide by default - only a logical event should make it appear */
    opacity: 0;

    /* Move to top of DOM */
    z-index: 999999; 

    /* push to top of page */
    position: fixed;
    /* hide from page for animates slide down*/
    top: -50px;
    left: 0;

    /* add spacing on left and right */
    margin: 0 2.5%;
    /* make sure it's not full-page */
    width: 95%;

    /* Make all content inside auto-position itself */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    /* Font Color */
    color: var(--banner__font_color);
    font-weight: var(--fw-semi-bold)!important;

    /* Set box-shadow so sticks out more on page */
    box-shadow: var(--box-shadow-default);

    /* Default Background Color */
    background-color: var(--banner__bg_color);
    /* background-color: var(--banner__bg_color); */
    

    /* Universal border styling */
    border-radius: var(--border-radius-default);
    
    /** Banner slides down and then back up.
    *  - NOTE: The math on this is tricky and doesn't behave as I'd expected.
                There is a direct connection between the two, so changing
                one will affect the other. ( Should research this more in the future so more flexible.)
    */
    transition:
        ease-in var(--banner-ease-in), /* SLIDE OUT - ease-in is the duration of hide */
        ease-out var(--banner-ease-out); /* SLIDE IN - ease-out is the duration of show */   
}




.banner-parent:has(.banner__error){
    background-color: var(--banner__bg_color_danger) ;
}


.banner__title{
    font-weight: var(--fw-bold);
}

.banner-message{
    /*TODO:: 05/18/22 #EP || Add content */
    /* Add space between edges and content */
    padding: .25rem;
    margin: auto .5rem;
    font-size: .9rem;
}


.banner-x
{ /* Create a perfectly even X element to close */
    height: 100%;
    font-weight: 300;
    /* transition: ease-in-out .2s; */
    font-size: 1.50rem;
    margin: auto .5rem;
    padding: 0 .4rem .25rem .75rem; /*This is required to center the x*/
    border-left: 1.25px solid rgba(255,255,255, .7);
    line-height: 1.45;
}

/* .banner-x::before{
    border-left: 2px solid white;
} */

.banner-x:hover
{
    font-weight: 400;
    cursor: pointer;
}

.banner-parent:hover
{
    box-shadow: var(--box-shadow-hover);
}
