

@keyframes hover {
	100% {
		transform: translateX(-30rem);
	}
}
/*------------------------------------------------------------------------------
SLIDE DOWN ANIMATION */

@keyframes slideDown {
    0%, 100% { -webkit-transform: translateY(-50px); }
    10%, 90% { -webkit-transform: translateY(0); }
}
@-webkit-keyframes slideDown {
    0%, 100% { -webkit-transform: translateY(-50px); }
    10%, 90% { -webkit-transform: translateY(0px); }
}
@-moz-keyframes slideDown {
    0%, 100% { -moz-transform: translateY(-50px); }
    10%, 90% { -moz-transform: translateY(0px); }
}



/*------------------------------------------------------------------------------
SLIDE RIGHT */
@keyframes slide-right {
	0% {
		opacity: .6;
    transform: translateX(-100px);
	}
  80% {
		transform: translateX(2px);
    opacity: .7;
	}
  90% {
		transform: translateX(-1px);
    opacity: .8;
	}
  100% {
		transform: translateX(0px);
    opacity: 1;
	}
}


/* Used for shake on hover */
@keyframes shake {
  0% { 
     -webkit-transform:  translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  10% { 
     -webkit-transform:  translate(-1px, -1px);
    transform: translate(-1px, -1px); 
  }
  20% { 
     -webkit-transform:  translate(-3px, -3px);
    transform: translate(-3px, -3px); 
  }
  30% { 
     -webkit-transform:  translate(0px, 0px);
    transform: translate(0px, 0px); 
  }
  40% { 
     -webkit-transform:  translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  50% { 
     -webkit-transform:  translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  60% { 
     -webkit-transform:  translate(3px, 3px);
    transform: translate(3px, 3px);
  }
  70% { 
     -webkit-transform:  translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  80% { 
     -webkit-transform:  translate(-1px, -1px);
    transform: translate(-1px, -1px);
  }
  90% { 
     -webkit-transform:  translate(1px, 2px);
    transform: translate(1px, 2px);
  }
  100% { 
     -webkit-transform:  translate(1px, -2px);
    transform: translate(1px, -2px);
  }
}


/* ANIMATIONS FOR HIDING AND FADING CONTENT */
.remove-animation {
    animation: none !important;
  }

.fade-in {
    animation-name: FadeIn;
    animation-duration: 200ms;
    transition-timing-function: linear;
    
  }
  .fade-out {
    animation-name: FadeOut;
    animation-duration: 1000ms;
    transition-timing-function: linear;
    opacity: 0;
  }
  
  .fade-in-out {
    /* animation-name: slide-in; */
    animation-name: FadeOut;
    animation-duration: 5s;
    transition-timing-function: linear;
  }

@keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-moz-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-o-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-ms-keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes FadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @-moz-keyframes FadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  

/* FADING OUT */
  @-webkit-keyframes FadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @-o-keyframes FadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @-ms-keyframes FadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }


  
.bounce {
  animation: bounce 100ms ease;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-5px);}
	60% {transform: translateY(-5px);}
}
@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-5px);}
	60% {transform: translateY(-5px);}
}
@-ms-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-5px);}
	60% {transform: translateY(-5px);}
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-5px);}
	60% {transform: translateY(-5px);}
}