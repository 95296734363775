:root {
  --input-h: 2rem;
  --input-w: 100%;
}


input *, select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: inherit;
  
  
  text-align: center;
  align-items: center;
  
  border: inherit;
  border-radius: inherit;
  border: inherit;
}

input {
  font-size: var(--fs-400);
  font-weight: var(--fw-regular);
  letter-spacing: var(--ls-300);
}

/*TODO:: 05/13/22 #EP || Adam to see if he prefers this instead */
/* input:not(:placeholder-shown) text
{ /* If input object has a value, hide the text* /
    opacity: 0;
} */

input:focus::-webkit-input-placeholder,  /* WebKit browsers */
input:focus:-moz-placeholder,  /* Mozilla Firefox 4 to 18 */
input:focus::-moz-placeholder,  /* Mozilla Firefox 19+ */
input:focus:-ms-input-placeholder,  /* Internet Explorer 10+ */
input:focus::placeholder  /* Everything else */
{ /* When input selected, hide placeholder text for ALL platforms */
  opacity: 0; /* Opacity because is one of the most browser resource friendly */
}

input[type='email'],
input[type='password'],
input[type='text'],
input[type='button']
{    
  min-height: var(--input-h);
  width: 100%; /* Fit Full container */
  max-width: 328px; /* Never get bigger than this, but can get smaller for mobile */
}

input[type='email'],
input[type='password'],
input[type='text'] {
    background-color: var(--clr-input-bg);
    /* border: 2px solid var(--clr-input-bdr); */
    border: 2px solid transparent;
    border-radius: 5px;
    padding: .25rem .5rem;

    outline: none; /* Prevent input element from having an outline */
    
    filter: brightness(99%); /* Makes appear as faded */

    /* When focused, animate into changes then back when losing focus */
    -webkit-transition: ease-in-out .2s;
    -moz-transition: ease-in-out .2s;
    -o-transition: ease-in-out .2s;
    transition: ease-in-out .2s;

    /* font-style: normal; */
    /* font-weight: 500; */
    /* font-size: 16px; */
    /* line-height: 18px; */
    color: var(--clr-input-fnt);
  }
  

  input[type='password']:hover,
  input[type='text']:hover
  { /* Focused inside input */
    border: 2px solid var(--clr-input-bdr);
    /* border-radius: 2px; */
    filter: brightness(100%);
    
  }

input[type='password']:focus,
input[type='text']:focus,
input[type='password']:focus-within,
input[type='text']:focus-within
{ /* Focused inside input, make obvious */
  border: 2px solid var(--clr-input-fcs);
  border-radius: 2px;
  background-color: var(--clr-bg-1);
  filter: brightness(100%);
}

  
  
input[type='password']:has(::-ms-value),
input[type="text"]:has(::-ms-value)
  {
    filter: brightness(100%); /* Appear as darker when hover*/
  }
  

button,
input[type='button']
{
 
  align-items: center;
  padding: 0px;
  background-color: #105FF0;
  color: #FFFFFF;
  height: var(--input-h);
  outline: none;
  border: 0;
  border-radius: var(--border-default-2px);
  cursor: pointer;
  
  /* Inside auto layout */
  /* margin: auto; */
  text-align:   center; 
  vertical-align: middle;  
}

input[type='button']
{
  margin: auto;
}
  
button:hover,
input[type='button']:hover 
{ /* When hover over button within login component */
    background-color: #326FE3;
}

select {
  height: var(--input-h);
  border: 2px solid var(--clr-input-bdr);
  text-align: center;
  text-align: -webkit-center;
  padding: 0 .5rem;
  
  
  
}

select:hover {
  border: 2px solid var(--clr-input-fcs);
  border-radius: var(--border-default-2px);
  filter: brightness(100%);
}

select:active {
  direction: ltr;

}

.btn{
  font-weight: var(--fw-bold);
  background-color: var(--clr-input);
  border-radius: var(--border-radius-default);
}
.btn:hover{
    background-color: var(--clr-input-fcs);
}

.btn-secondary        { background-color: var(--clr-fnt-100) ;}
.btn-secondary:hover  { background-color: var(--clr-fnt-200)  ;}

.btn-danger           { background-color: hsla(360, 100%, 36%, .9);}
.btn-danger:hover     {background-color: hsla(360, 100%, 36%, 1);}


/* .btn-dark {

} */

button{
  border: 2px solid transparent;
  padding: .25rem;
  display: flex;
  border-radius: var(--border-radius-default);
}

button.active {
  border: 2px solid white;
  /* font-weight: var(--fw-bold); */
  filter: brightness(100%);
}

button[disabled] {
  background-color: var(--clr-input-bg);
  cursor: inherit;
}