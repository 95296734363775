/*------------------------------------------------------------------------------
LOADING STYLES */

.loading__container
{ /* Wrapper around  */

    height: 50vh;
    margin-top: 15vh;
}

.loading-animation
{ /* the SPAN around the animation */
    max-height: 134px;
    max-width: 269px;
}
.loading-animation canvas {
    border: none;
    transition: ease-in-out 200ms;
}
