.detail_information__wrapper{
    display: flex;
    /* margin: auto; */
    height: 100%;
    /* width: 100%; */
    /* width: 25px;
    height: 25px; */
    
}

/* .detail_information__wrapper:hover {
    cursor: pointer;
} */


.detail_information__content {
  /* info container for specific content. Hover to see text */
  
    margin: auto 0;
    opacity: 0 !important;
    position: relative;
    width: 0;
    height: 0;
    overflow: hidden;
    
    color: var(--clr-fnt-500);
    font-size: var(--fs-300);
    font-weight: var(--fw-regular);
    letter-spacing: var(--ls-300);
    
    /* pointer-events: none; */

    transform: translateX(-15px);
    transition: ease-in-out 200ms !important;
}

.detail_information__wrapper:hover .detail_information__content 
 {
    opacity: 1 !important;
    transform: translateX(0px);
    width: fit-content;
    height: fit-content;

    /* pointer-events: all; */
}

/* .detail_information__wrapper:hover:has(.detail_information__content:hover) {
    opacity: 1;  
} */

.detail_information__default {
 }

.detail_information__light *{
    color: var(--clr-light-800);
    stroke: white;
    fill: white
}

.detail_information__icon,
.detail_information__icon svg{
    /* Forcing the info-bordered.SVG to be thicker. */
    display: flex;
    margin: 0;
    padding: 0;
    stroke: var(--clr-accent);

}

.detail_information__continue {
    /** CHEVRON 
     *
     * Greater Than arrow to continue in card 
     */

  /* color: var(--clr-accent); */
  color: var(--clr-light-500);
  font-weight: var(--fw-bold);
  /* stroke: red; */
  /* stroke-width: 10px; */
  font-size: 1.5rem;
  /* padding: 0; */
  /* content: "#65310"; */
  margin: 0;
  padding: 0;

  transition: ease-in-out 200ms;
  transform: scaleX(50%);
}