/*!! WARNING : This is being imported in root/assets/css/style.css */

:root {
    --border-radius-card: 20px;
    --border-default-card: 2px solid var(--clr-fnt-100);
    --border-default-card-hover: 2px solid var(--clr-fnt-200);
}

/*------------------------------------------------------------------------------
CARD - Cards used through the app */

.page__div .page__section.cards {
    /* Removes border from page__section if cards only */
    border: 0;
    background: none;
}

.cards__wrapper{
    /* Wrapper around ALL cards */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 100%;
    margin: auto;
    justify-content: center;
    padding-bottom: 20px;
}

.card__wrapper{
    /* Each individual card uses this wrapper */
    position: relative;
    display: flex;
    flex-wrap: none; 
    flex: 20% 4;
    flex-direction: column;
    margin-inline: auto;
    padding: 0 .5rem .5rem;
    
    /* keeps content from exiting cards */
    margin: 1rem 2rem 1rem 1rem;
    
    /* width: fit-content; */
    max-width: 375px;
    min-width: 375px;
    min-height:200px;
    background-color:var(--clr-bg-1);
    border: var(--border-default-card);
    border-radius: var(--border-radius-card);
    
    
    transition: ease-in-out .2s;
}

.card__wrapper:hover{
    border: var(--border-default-card-hover);
}

.card{
    
    /* Standard page card */
    
    /* text-overflow: wrap; */
    flex: 40% 2;
    /* width: 70%; */
    margin: auto .5rem;
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: none;
    text-wrap: wrap;
    
}

.card__title{
    /* Title/Header of the Cards Section with column labels*/
    border-bottom: var(--border-default-2px);
    width: 100%;
    display: flex;
    padding: .5rem;
    background-color: var(--clr-bg-3);
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-650);
    
    
}

.card__title_header{
    /* Title/Header of the Cards Section with column labels*/
    
    display: none;
}

/* .card__status {

} */

.card__hr{
    /** Horizontal Rule  in card between elements*/
    
    width: 100%;
    margin: 0 auto;
    padding: 0;
    background-color: var(--systemGray5);
    /* margin: 1rem 0; */
    height: 1px;
    
}

.card__header {
    /* First container within card containing primary info
    
        - On Sensors page, this is the business name
    */
    margin: 0 .5rem 0 0;
    /* border: var(--border-default-2px);
    border-radius: var(--border-default-2px); */
    padding: .5rem;
    /* display: flex; */
    justify-content: space-between;
    flex-wrap: wrap;
    
    /* flex: 5% 1; */
    /* flex-grow: initial; */
    
    /* height: 100%; */
    /* flex: 100%; */    
}


.card__header_title{
    /* Primary name of the card 
        - on Sensors it's the business name
    */
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-700);
    flex: 100%;
    width: 100%;
    letter-spacing: var(--ls-700);   
}

.card__header_subTitle{
    /* Secondary title to card. 
        - Used in Alerts as the actual alert param
    */
    
    flex: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-content: center;  /* for Icon */
    align-items: center;    /* For Msg */
    text-align: center;

    
    padding: .5rem 0rem;
    margin: 0;
    overflow: auto;
    /* max-height: 85px; */
    /* TODO: 20221213 #EP || Add logic here so this is easier to manage if grows */
    width: 100%;

    gap: .5rem;

    
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-650);
    color: var(--clr-fnt-800);
    letter-spacing: var(--ls-500);
    
    
    
    /* font-weight: var(--fw-semi-bold);
    font-size: var(--fs-600);
    color: var(--clr-fnt-800);
    letter-spacing: var(--ls-500); */
}


.card__header_details,
.card__header_details_mfg,
.card__header_details__mfg_label {
    /* Left column within card containing secondary info  */
    /* margin: auto .5rem auto 0;
    border: var(--border-default-2px);
    border-radius: var(--border-default-2px);
    padding: .5rem; */
    /* flex: 100%; */
    /* flex: 100%; */
}

.card__header_details_mfg{
    /* font-weight: var(--fw-bold); */
    /* border: var(--border-default); */
    color: var(--clr-fnt-700);
    font-size: var(--fs-500);
    /* margin: auto; */
}


/* LABELS WITHIN CARD */
/*TODO:: 20221023 #EP || Add better logic for handling wrapping tags  */
.card__header__detail_labels{
    /** Labels for the left column within card containing secondary info  */
    
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    justify-content: flex-start;
    margin: auto;
    padding: 1rem 0;
    gap: 1rem;
    /* height: 85px; */
    /* overflow: auto; */
    overflow: hidden;
    width: 100%;
    /* max-width: 80vw; */

    /* border: 1px solid red; */
    

    font-weight: var(--fw-bold);
    font-size: var(--fs-500);
    color: var(--clr-fnt-700);
    
}

.card__content_labels_wrapper{
    /** force labels to start on left */
    justify-content: left !important;
}

.card__header_details_label{
    /* Individual label */
    flex: 10%;
    max-width: fit-content;
    color: var(--clr-fnt-700);
    border: var(--border-default);
    border-radius: .75rem;
    padding: .25rem .5rem;
    margin: .25rem;
    font-size: var(--fs-300);
    background-color: var(--clr-bg-1);
}

/* .card__summary {

} */

.card__content {
    margin: 1.5rem 0rem 1rem;
    
    /* display: flex;z
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    flex-flow: column;
    padding: 0;
    margin: 0; */
    /* border: 1px solid red; */
}


.card__content_item_title{
    /** Each section of content can have a title */

    position: absolute;
    transform: translateY(-1rem);
    display: inline-block;
    width: 90%;
    height: 1.5rem;
    /* width: fit-content; */
    /* margin: auto 0; */
    padding: 0 1rem 0 .5rem;
    /* padding: 0 1rem; */
    
    
    /* Forcing text in center */
    display: flex;
    flex-direction: row;
    justify-content: left;
    vertical-align: center;
    align-items: center;
    
    
    color: var(--clr-fnt-1000);
    font-weight: var(--fw-bold);
    font-size: var(--fs-400);
    font-style: normal;
    letter-spacing: var(--ls-700);
}

.card__content_item_title_alt{
    display: inline-block;
    /* width: 90%; */
    height: 1.5rem;
    /* width: fit-content; */
    /* margin: auto 0; */
    /* padding: 0 1rem 0 .5rem; */
    /* padding: 0 1rem; */
    
    
    /* Forcing text in center */
    display: flex;
    flex-direction: row;
    justify-content: left;
    vertical-align: center;
    align-items: center;
    
    
    color: var(--clr-fnt-1000);
    font-weight: var(--fw-bold);
    font-size: var(--fs-400);
    font-style: normal;
    letter-spacing: var(--ls-700);
}


.card__content_metrics {
    /** Parent wrapper around ALL Metrics
    * 
    * - Used in Sensors page as wrapper around Metrics
    * - Used in Alerts card as wrapper around Alert message / dropdown
    */
    
    display: flex;
    /* position: relative; */
    flex: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: left; */
    
    margin: .5rem; 
    
    
    /* margin: auto auto 2rem auto; */
    align-items: center;
    align-content: center;
    
}

.card__content_metric {
    /** Wrapper around individual Metric / stat
    */

    position: relative;
    display: flex;
    flex: 20%;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    text-overflow: clip;
    /* overflow: hidden; */
    /* align-content: center;
    align-items: center;
    vertical-align: center; */

    
    /* border-left:    1px solid var(--clr-fnt-100); */
    /* border-bottom:  2px solid var(--clr-fnt-100); */
    /* border-radius:10px; */

    padding: .5rem 0;
    color: var(--clr-fnt-700);
}

/** Container holding Alerts / Notifications count in Metrics */
.metric__isAlertCount{
    position: absolute; 
    top: .3rem;
    right: 10%;
    padding: .5rem .25rem;
    
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    
    /* border: 1px solid var(--clr-fnt-100); */
    
    min-width: 17px;
    max-height: 17px;
    border-radius: .5rem;
    
    text-decoration: unset;
    font-weight: unset;
    font-family: unset;
    color: unset;
    

    background-color: var(--danger-active);
    border: 1px solid var(--danger);
    color: var(--clr-fnt-1000);
    font-weight: var(--fw-bold);
    font-size: var(--fs-200);
}

.card__content_metric__title{
    /** Used when metrics are to the right */

    /* position: absolute; */
    /* bottom: -1rem; */
    font-size: var(--fs-400);
    color: var(--clr-fnt-800);
    letter-spacing: var(--ls-700);
    word-wrap: break-word;
    width: 80px;
    height: 3rem;
    display: grid;
    align-items: center;

    
}
.card__content_metric__title__absolute{
    /** Used when metrics are to the right */

    position: absolute;
    top: .75rem;
    font-size: var(--fs-500);
    color: var(--clr-fnt-900);
    letter-spacing: var(--ls-500);
}

.card__content_stat {
    /** Contains the Icon and the current state of the metric and the icon. */

    flex: 20%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    
    align-items: center;
    display: flex;
    text-align: center;
    /* margin: .5rem .25rem; */
    /* margin: 1.5rem 0 0 .2rem; */
    gap: .25rem; 
    text-align: top;
    padding-bottom: 0rem;
    
    
}

/* .card__content_metrics_name_value{
    white-space: nowrap;
    overflow: hidden;
    
    margin: 0;
    gap: 0;
    text-align: left;
    font-size: var(--fs-400);
    color: var(--clr-fnt-800);
    padding-left: .25rem;
} */
.card__content_metrics_name_value{
    white-space: nowrap;
    overflow: hidden;
    
    margin: 0;
    gap: 0;
    text-align: left;
    font-size: var(--fs-600);
    color: var(--clr-fnt-800);
    font-weight: var(--fw-semi-bold);
    padding-left: .25rem;
}
.card__content_metrics_name_value[data-value="normal"]{
    color: var(--success);
}
.card__content_metrics_name_value[data-value="low"],
.card__content_metrics_name_value[data-value="high"]{
    color: var(--danger);
}


.card__content_metric_sub__wrapper{
    flex: 70%;
    align-items: left;

    display: flex;
    flex-direction: column;
    /* flex-wrap: nowrap; */
    /* white-space: nowrap; */
    
    align-content: center;
    vertical-align: center;
    font-size: 12px;

    padding: .5rem 0 0 .5rem;
    /* border-bottom: 2px solid var(--clr-fnt-100); */
    /* border-top: 2px solid transparent; */
    /* border-radius: 10px; */
    
    /* border-top: 2px solid var(--clr-fnt-100);
    border-radius:  0 10px; */
}

.card__content_metric_sub .title{
    color: var(--clr-fnt-500);
}
.card__content_metric_sub .value{
    color: var(--clr-fnt-600);
}

.card__content_metrics_icon{
    float: right;
    align-items: right;
    align-content: right;
}


.card__content_item{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    padding: 0 .5rem .5rem;
    text-align: left;
}



/*----------------------------------------------------------------------------*/
/* card__controls - top right corner navigation
    - Detail > ( i ) > 
*/

.card__controls {
    /* Button(s) within card 
        - on Sensors page, this is the business name
        - In sensors page, this is the button to view the sensor
    */
    display: flex;
    
    position: absolute;
    top: 0rem;
    right: 0rem;
    font-weight: var(--fw-semi-bold);
    
    letter-spacing: var(--ls-300);
    /* gap: .3rem; spacing between content within nav */
    margin: .5rem 1rem;
    background: none;
    
    /* Original formatting when view btn wasn't top right  */
    /* flex: 10% 2; */
    /* width: 30%; */
    /* height: 100%; */
    /* margin: auto; */
    /* border-radius: 1rem; */
    
    
    /* justify-content: right; */
    /* align-items: center; */
    /* margin: 0; */
    /* padding: .5rem 1rem; */
    
    
}



.detail_Information { /* Wrapper around the SVG Information Icon */
    display: flex;
    margin: auto;
}
.detail_Information:hover { 
    cursor: pointer;
}

.detail__font{
    /** Word Detail */
    color: var(--clr-light-100);
    font-weight: var(--fw-bold);
    margin: auto;
}

.card__back{
    color: var(--clr-accent);
    font-weight: var(--fw-bold);
    /* stroke: red; */
    /* stroke-width: 10px; */
    font-size: 1.75rem;
    /* padding: 0; */
    content: "&#65310";
}

.infoContinue{
    /** Wrapper around Icon to load Sensor Specific View
     *
     * Chevron html entity.
     */
    
    /* color: var(--clr-accent); */
    color: var(--clr-light-400);
    font-weight: var(--fw-bold);
    /* stroke: red; */
    /* stroke-width: 10px; */
    font-size: 1.5rem;
    /* padding: 0; */
    /* content: "#65310"; */
    margin: 0 -.5rem 0 0;
    padding: 0;
    
    transition: ease-in-out 200ms;
    transform: scaleX(45%);
}

.card__controls .icon__container {
    margin: 0;
    /* height: 90%; */
}

.card__controls .icon__container,
.card__controls .icon__container svg {
    /* Removes all weird padding from icon that I don't want here.*/

    padding: 0 !important;
    margin: 0 !important;
    /* height: 85%; */
    height: 20px;
}

.card__content_stat .icon__container{
    /* Set width of ICON to minimum */

    width: fit-content !important;
}

.card__content_stat svg path,
.card__controls .icon__container svg path {
    /** Making info SVG border thicker around the DETAIL info */
    
    stroke: var(--clr-accent);
    stroke-width: 1.75px;
  }
/* 
.card__controls:hover,
.card__controls:hover .infoContinue,
.detail_Information.icon__container:hover, */
.infoContinue:hover {
    color: var(--clr-accent);
    cursor: pointer;
}

.card__wrapper .icon__container:hover{
    /* Remove box shadow behaviors used in normal icons as not wanted in cards */
    box-shadow: none !important;
    border: none !important;
    /* cursor: pointer !important; */
}

/* .card__wrapper .icon__container::after {
    content: 'ADD';
} */



/* .detail_Information,
.card__content_info::after,
.card__content_info{ */

.detail_Information .card__content_info::after{
    /* width: 0; */
    /* transform-origin: 0 10rem; */
    transition: ease-in-out 500ms;
    opacity: 0;
}

/* .detail_Infomration .card__content_info::after{
    opacity: 1;
    transition: ease-in-out 200ms;
} */

/* .detail_Information span.card__content_info{
    opacity: 1;
    transition: ease-in-out 2000ms !important;
} */


.detail_Information .card__content_info{
    /* info container for specific content. Hover to see text */

    margin: auto 0;
    opacity: 0 !important;
    position: relative;
    width: 0;
    height: 0;
    overflow: hidden;
    
    color: var(--clr-fnt-500);
    font-size: var(--fs-300);
    font-weight: var(--fw-regular);
    letter-spacing: var(--ls-300);
    
    transform: translateX(-15px);
    transition: ease-in-out 200ms !important;
}



.detail_Information:hover .card__content_info{
    /** Slides text hidden detail_Information
    */
    opacity: 1 !important;
    transform: translateX(0px);
    width: fit-content;
    height: fit-content;
}

.card__button_bottom{
    display: flex;
    flex-wrap: wrap;
    margin: auto 0 0 0;
    bottom: 0;
}

/*----------------------------------------------------------------------------*/
/* FOOTER */

.card__footer {
    margin-top: auto;
    /* position: relative; */
    padding: .5rem .5rem 0 .5rem;
    border-top: var(--border-default-2px);
    display: flex;
    flex-wrap: wrap;
    bottom: 0;
    /* flex: 100%; */
    justify-content: space-between;
}

.card__footer span b {
    /* font-weight: var(--fw-bold); */
    color: var(--clr-fnt-700);
}
.card__footer span i {
    /* Secondary font within footer */
    color: var(--clr-fnt-500);
}

.card__syncStatus {
    display: flex;
    margin: auto 0;
    padding-right: 5px;
}

.card__syncStatus.online{
    color: var(--systemGreen);
    
}

.card__syncStatus.offline{
    color: var(--systemRed);
}

/*----------------------------------------------------------------------------*/

@media only screen and (max-width: 600px) {
    .card__wrapper{
        max-width: 80vw;
        min-width: 80vw;
        margin: 1rem auto;
    }
    
    .card__content_metrics{
        gap: 0rem;
        
    }

    .card__content_metric{
        /* flex: 30%; */
    }
    
}

@media only screen and (max-width: 400px) {

    .card__wrapper{
        max-width: 100vw;
        min-width: 80vw;
        margin: 1rem auto;
    }
    
    .card__content_metrics{
        /* flex-direction: column; */
        gap: .5rem;
        justify-content: space-between;
        align-content: unset;
        align-items: unset;
        flex: 100%;
        /* width: 90%; */
    }

    .card__content_metric{
        /* flex: 100%; */
    }
}

@media only screen and (max-width: 300px) {

    .card__content_metrics{
        /* flex-direction: column; */
        align-content: center;
        align-items: center;
    }
    .card__content_metric{
        flex: 100%;
    }
}