/*------------------------------------------------------------------------------
HEADER  */

:root {
    /* HEADER / NAVIGATION */
    --header-font-color-light: #000000;
    --header-font-color-dark: #FFFFFF;
    --header-bg-color: rgba(30, 48, 65, .05);
    --header-height: 40px;
}

header
{
    position: fixed; /* always in same location outside of container limitations */
    top: 0; /* Fits on very top of page */
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: var(--header-height);
    gap: 1rem;
    
    background-color: var(--clr-header-bg);
    color: var(--clr-header-fnt);
    font-weight: lighter;
    font-size: 0.85rem;
    
    z-index: 5;
    
    min-width: 350px;
    transition: ease-in-out .2s;
}

.header__path
{ /* wrapper around brand name and current page */
    display: flex;
}

.header__brandName {
    display: flex;
    align-items: center;
    padding: 0 .25rem 0 1rem;
    margin: auto 0;
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-650);
    height: 100%;
    gap: .5rem;
    letter-spacing: 1px;
    width: 30%;
    min-width: fit-content;
    transition: ease-in-out .2s;
    
    border-bottom: 1px solid transparent; /* pushing up by 1 pixel to make in alignment with pagen ame*/
}


.header__link {
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--clr-header-a);
    letter-spacing: 1px;
    text-decoration: none;
    text-decoration: underline solid transparent 1px;
    text-underline-position: above;
    -ms-text-underline-position: above;
    transition: ease-in-out .2s;
}

.header__link_path {
    /* Path has faint underline */
    font-weight: var(--fw-bold);
    text-decoration: underline solid var(--clr-fnt-200) 1px;
    text-underline-offset: 2px;
    text-underline-position: below;
    -ms-text-underline-position: below;
}

.header__link:hover,
.header__link_path:hover {
    /* Hover brand name or path and get border bottom */
    text-decoration: underline solid var(--clr-fnt-500) 1px;
    text-underline-offset: 2px;
    text-underline-position: below;
    -ms-text-underline-position: below;
    cursor: pointer;
}

.header__pathContainer 
{ /* wrapper around forward slash and then the pag name */
    margin: auto 0;
    gap: .25rem;
    height: 100%;
    display: flex;
    /* align-items: center; */
    font-weight: 300;
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: capitalize;
    padding-bottom: 2px solid transparent;
    
}

.header__pathSymbol
{ /* The symbol used between paths */
    margin: auto;
    /* padding-bottom: .25rem; */
    display: flex;
    align-items: center;
    height: 100%;
    color: rgba(255,255,255,1);
}




.header__account
{ /* Wrapper around Account Type and Name */
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin: auto 0;
    height: 100%;
    font-weight: 400;
    /* font-size: 1.1rem; */
    letter-spacing: 1px;
    text-transform: capitalize;
    padding-right: 2rem;
}

.header__accountType,
.header__accountName {
    display: flex;
    align-items: center;
    height: 100%;
}

.header__accountName
{
    padding-top: .25rem;
    font-weight: bold;
    font-size: .9rem;
}
.header__accountType{
    font-size: .7rem;
}


.header__menu{
    display: flex;
    align-items: center;
    padding-right: .5rem;
}


@media screen and (max-width: 768px) {
    .header__account{
        padding: 0;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .header__accountName{
        font-size: .7rem;   
    }
    .header__accountType
    {
        font-size: .6rem;
    }
}


@media screen and (max-width: 600px) {
    /* Adjusting header to be more mobile friendly by... */
    header{
        /* gap: 1rem; */
        justify-content: unset;
    }

    .header__menu{
        top: .25rem;
        right: 0;
        position: absolute;
    }
    
    /* .header__path{
        /* Makes sure brand name and path  is in top-left corner * /
       position: absolute;
    } */
    .header__pathname{
        padding-top: .25rem;
    }

    /* Push account/business name under Bair Logo and route */
    /* .header__account {
        position: absolute;
        left: .75rem;
        padding-top: .25rem;
        height: 100%;
        align-items: baseline;
        gap: 0;
        display: flex;
        flex-direction: row;
        text-align: left;
        float: left;
        align-items: left;
        align-content: left;
        padding-top: 1.25rem;
    } */

    /* Account or Biz Name here */
    .header__account {
        position: absolute;
        justify-content: center;
        /* left: .75rem; */
        /* padding-top: .25rem; */
        height: 100%;
        width: 100%;
        /* margin-top: auto; */
        align-items: baseline;
        gap: 0;
        display: flex;
        flex-direction: row;
        /* text-align: left;
        float: left;
        align-items: center;
        align-content: left; */
        /* padding-top: 1.25rem; */
    }

    .header__accountName{
        vertical-align: bottom;
        
    }
    .header__accountType
    {
        vertical-align: bottom;
        display: none;
    }
}

