/*------------------------------------------------------------------------------
    MARGINS
 */
.m-x-a    { margin-left: auto;              margin-right   : auto; }
 .m-x-0   { margin-left : var(--rem-0);     margin-right   : var(--rem-0);}
.m-x-100  { margin-left : var(--rem-100);   margin-right   : var(--rem-100);}
.m-x-200  { margin-left : var(--rem-200);   margin-right   : var(--rem-200);}
.m-x-300  { margin-left : var(--rem-300);   margin-right   : var(--rem-300);}
.m-x-400  { margin-left : var(--rem-400);   margin-right   : var(--rem-400);}
.m-x-500  { margin-left : var(--rem-500);   margin-right   : var(--rem-500);}
.m-x-600  { margin-left : var(--rem-600);   margin-right   : var(--rem-600);}
.m-x-700  { margin-left : var(--rem-700);   margin-right   : var(--rem-700);}
.m-x-800  { margin-left : var(--rem-800);   margin-right   : var(--rem-800);}
.m-x-900  { margin-left : var(--rem-900);   margin-right   : var(--rem-900);}
.m-x-1000 { margin-left : var(--rem-1000);  margin-right   : var(--rem-1000);}

.m-l-a    { margin-left : auto; }
.m-l-0    { margin-left : var(--rem-0);   }
.m-l-100  { margin-left : var(--rem-100); }
.m-l-200  { margin-left : var(--rem-200); }
.m-l-300  { margin-left : var(--rem-300); }
.m-l-400  { margin-left : var(--rem-400); }
.m-l-500  { margin-left : var(--rem-500); }
.m-l-600  { margin-left : var(--rem-600); }
.m-l-700  { margin-left : var(--rem-700); }
.m-l-800  { margin-left : var(--rem-800); }
.m-l-900  { margin-left : var(--rem-900); }
.m-l-1000  { margin-left : var(--rem-1000); }

.m-r-a    { margin-right : auto; }
.m-r-0    { margin-right   : var(--rem-0);}
.m-r-100  { margin-right   : var(--rem-100);}
.m-r-200  { margin-right   : var(--rem-200);}
.m-r-300  { margin-right   : var(--rem-300);}
.m-r-400  { margin-right   : var(--rem-400);}
.m-r-500  { margin-right   : var(--rem-500);}
.m-r-600  { margin-right   : var(--rem-600);}
.m-r-700  { margin-right   : var(--rem-700);}
.m-r-800  { margin-right   : var(--rem-800);}
.m-r-900  { margin-right   : var(--rem-900);}
.m-r-1000 { margin-right   : var(--rem-1000);}


.m-y-a    { margin-top: auto;             margin-bottom   : auto;}
.m-y-0    { margin-top : var(--rem-0);    margin-bottom   : var(--rem-0);}
.m-y-100  { margin-top : var(--rem-100);  margin-bottom   : var(--rem-100);}
.m-y-200  { margin-top : var(--rem-200);  margin-bottom   : var(--rem-200);}
.m-y-300  { margin-top : var(--rem-300);  margin-bottom   : var(--rem-300);}
.m-y-400  { margin-top : var(--rem-400);  margin-bottom   : var(--rem-400);}
.m-y-500  { margin-top : var(--rem-500);  margin-bottom   : var(--rem-500);}
.m-y-600  { margin-top : var(--rem-600);  margin-bottom   : var(--rem-600);}
.m-y-700  { margin-top : var(--rem-700);  margin-bottom   : var(--rem-700);}
.m-y-800  { margin-top : var(--rem-800);  margin-bottom   : var(--rem-800);}
.m-y-900  { margin-top : var(--rem-900);  margin-bottom   : var(--rem-900);}
.m-y-1000 { margin-top : var(--rem-900);  margin-bottom   : var(--rem-900);}

.m-t-a    { margin-top : auto; }
.m-t-0    { margin-top : var(--rem-0);}
.m-t-100  { margin-top : var(--rem-100);}
.m-t-200  { margin-top : var(--rem-200);}
.m-t-300  { margin-top : var(--rem-300);}
.m-t-400  { margin-top : var(--rem-400);}
.m-t-500  { margin-top : var(--rem-500);}
.m-t-600  { margin-top : var(--rem-600);}
.m-t-700  { margin-top : var(--rem-700);}
.m-t-800  { margin-top : var(--rem-800);}
.m-t-900  { margin-top : var(--rem-900);}
.m-t-1000 { margin-top : var(--rem-1000);}

.m-b-a    { margin-bottom   : auto; }
.m-b-0    { margin-bottom   : var(--rem-0);}
.m-b-100  { margin-bottom   : var(--rem-100);}
.m-b-200  { margin-bottom   : var(--rem-200);}
.m-b-300  { margin-bottom   : var(--rem-300);}
.m-b-400  { margin-bottom   : var(--rem-400);}
.m-b-500  { margin-bottom   : var(--rem-500);}
.m-b-600  { margin-bottom   : var(--rem-600);}
.m-b-700  { margin-bottom   : var(--rem-700);}
.m-b-800  { margin-bottom   : var(--rem-800);}
.m-b-900  { margin-bottom   : var(--rem-900);}
.m-b-1000 { margin-bottom   : var(--rem-1000);}