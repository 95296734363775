/*------------------------------------------------------------------------------
FOOTER */

:root {
    --footer-height: 20px;
    --height-footer: var(--footer-height);
}

footer 
{  /* Branding Logo and Copyright info */
    position: fixed; /* Sticks to location  */
    bottom: 0; /* puts on bottom */
    padding: .75rem 2rem;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%; /* Makes fit full page */
    align-items: center; /* Makes child elements center */
    grid-gap: 1rem;
    font-size: .8rem;
    border-top: 1px solid var(--systemBlue);
    background-color: var(--systemBlue);
    z-index: 5;
    height: var(--footer-height);
}