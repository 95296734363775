:root {
  --table__title-height: 60px;
}

table,
.table {
  padding: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  /* border-collapse: collapse; */
  /* border: 1px solid red; */
  table-layout: fixed;
  min-width: 300px;
  min-height: 100px;
}



/* Creates a transparent scrollbar */
/* ::-webkit-scrollbar {
  position: relative;
  width: 10px;
  
}
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0,0,0,0);
  background-clip: padding-box;
  background-color: rgba(255,255,255,0.4);
  border-radius: 10px;
  
} */


.table__title
{ /* Top section with Search, table name, and results*/
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  /* height: var(--table__title-height); */
  position: sticky;
  top: -10rem;
  padding: .25rem 1rem;
  background-color: var(--clr-bg-3);
  border-radius:  var(--border-radius-default)
                  var(--border-radius-default)
                  0
                  0;
  z-index: 1;
  height: 4rem;
  font-weight: var(--fw-bold);
  letter-spacing: var(--ls-500);
  font-size: var(--fs-600);
  color: var(--clr-light-700);
}
.table__name
{ /* The header of the table ( h4 as of now 081122) */
  
}

.table__total{ /* total count of results - top right corner*/
  position: absolute;
  top: 0rem;
  right: var(--rem-500);
}

.table__filter{
  /* Search box to narrow down results */
  position: absolute;
  /* top: 1.25rem; */
  bottom: .25rem;
  right: 1rem;
}

.table__filter input[type='date'],
.table__filter input[type='text'] {
  /* max-width: 500px; */
  max-width: 30%;
  float: right;
  font-size: var(--fs-400);
  border-radius: var(--border-radius-modal);
  border: var(--border-default-2px);
}

.table__pagination{
  
  /* position: fixed; */
  /* bottom: 0rem; */
  /* margin-top: 1rem; */
  padding: .5rem;
  background-color: var(--clr-bg-2);
  border-radius:  0
                  0 
                  var(--border-radius-default)
                  var(--border-radius-default);
  font-size: var(--fs-300);
  letter-spacing: 2px;
  height: fit-content;
  
}

.modal__child .table__pagination{
  /* handling pagination within a modal - need to always be visible and on bottom */
  z-index: 1009;
  position: relative;
  
  font-size: +1rem;
  font-weight: var(--fw-bold);
  letter-spacing: var(--ls-700);
  margin: auto;
  /* padding: .5rem; */
  width: 100%;
  bottom: 0vh;
  /* left: 1rem; */
  /* overflow: scroll; */
  
  /* bottom: calc(100vh * -1); */
  
  /* left: 0; */
  
}

thead,.table__head{
  /* display: flex; */
  /* width: 100%; */
  cursor: pointer;
  word-wrap: break-word;
  margin: 0 .5rem;
}

.table__header_id{
  width: 5%;
  max-width: fit-content;
}


thead, th,
.table__header{
 width: 10%;
}


th,
.table__header_inline,
.table__header_id,
.table__header {
  padding: .5rem 0 .125rem;
  
  /*Uncomment to add a sticky top */
  /* top: calc(var(--table__title-height) ); */
  top: 0;
  background-color: var(--clr-accent-fcs);
  color: var(--clr-header-fnt);
  position: sticky;
  transition: ease-in 200ms;
  
  font-size: var(--fs-500);
  letter-spacing: var(--ls-500);
  font-weight: var(--fw-semi-bold);
  /* border: 1px solid var(--clr-dark-1000); */
}

/* .table__header_inline:hover,
th:hover,
.table__header_id:hover,
.table__header:hover{
  background-color: var(--clr-accent);
  color: var(--clr-header-fnt-hover);
} */

.table__header_inline{
  display: none;
}


tr,
.table__row {
  /* background-color: var(--clr-bg-1);
  color: var(--clr-fnt-1);
  font-weight: var(--fw-normal); */
  font-size: var(--fs-400);
}

td,
.table__cell {
  border: 1px solid rgba(255,255,255,0.1);
  font-size: var(--fs-100);
  padding: .25rem 0;
  text-align: center;
  background-color: var(--clr-bg-3);
  /* overflow: scroll; */
  /* word-wrap: break-word;  */
}

td{
  width: 100%;
}

.table__view-sensor{
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: .5rem;
}

.table__view-sensor-btn{
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 10rem;
}

.table__input{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  resize: none;
  align-items: center;
  text-align: center;
  border: none;  
}


tbody {
  min-height: fit-content;
  
  width: 100%;
  background-color: var(--clr-bg-2);
}

tbody tr{
  box-shadow: var(--box-shadow-default);
}

.modal__child tbody tr:last-child{
  /* When viewing in modal, put padding to bottom of table */
  margin-bottom: 5rem !important;
}





/*----------------------------------------------------------------------------*/
/* MEDIA QUERIES */

@media screen AND (max-width: 600px) {
  
  th,
  .table__header_id,
  .table__header{
    font-weight: var(--fw-bold);
    font-size: var(--fs-500);
    min-width: fit-content;
  }
  
  .table__header_inline{
    /* font-weight: var(--fw-semi-bold); */
    
    font-size: var(--fs-100);
    flex: 20%;
    min-width: 30%;
    max-width: 30%;
    /* margin-top: auto; */
    /* padding: .25rem 0; */
    display: flex;
    /* flex-direction: column; */
    /* text-align: center;
    align-items: center; */
    /* align-content: center; */
    justify-content: center;
    overflow: hidden;
    word-break:keep-all;
    word-wrap: break-word;
    background-color: var(--clr-bg-3);
    padding: .25rem;
    
  }

  .table__header_inline i
  { /* Table header IDs */
    
    border: 1px solid var(--clr-dark-1000);
    
    /* background-color: var(--clr-bg-2); */
    text-transform: uppercase;
    border-radius: 10px;
    padding: .125rem 1rem;
    width: 100%;
  }

  .table__header_actions {
    display: none;
  }

  .table__title {
    /* height: 100px; */
    flex-direction: column;
    align-items: unset; /* Removing this to make things fit full better */
  }

  
  
  .table__row, tr {
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    border-radius: 4px;
    border: var(--border-default-2px);
    font-size: var(--fs-100);
  }

  thead tr,
  .table__head .table__row {
    display: none;
  }

  
  
  td,
  .table__cell{
    display: flex;
    justify-content: flex-start;
    font-size: var(--fs-100);
    /* gap: 1rem; */
    width: 100%;
    padding: 0;
  }

  .table__view-sensor,
  .table__view-sensor-btn{
    min-width: 100%;
    padding: 0;
    font-size: var(--fs-500);
  }
  
  
  .table__cell_value{
    display: flex;
    align-content: center;
    flex: 100%;
    float: left;
    text-align: left;
    align-items: center;
    font-size: var(--fs-400);
    font-weight: var(--fw-bold);
    letter-spacing: 1px;
    margin: auto;
    height: var(--input-h);
    /* background-color: var(--clr-bg-1); */
    padding: 0 1rem
  }

  .table__cell_value input{
    min-width: 100%;
    flex: 100%;
  }
  

  .table__cell__button{
    right: 0;
    position: fixed;
  }

  .modal__child .table__pagination{
    /* position: sticky; */
    z-index: 1009;
    position: fixed;
    font-size: +1rem;
    font-weight: var(--fw-bold);
    letter-spacing: var(--ls-700);
    /* width: 100%; */
    width: 92vw;
    /* margin: auto; */
    margin-left: .1rem;
    bottom: 38px;
    /* padding: 50px; */
    /* margin: 0 .25rem; */
    /* bottom: calc(100vh) * - 1; */
  }
}




.table__view_card {
  /* TODO:: 101622 #EP || Add or remove this */
  position: fixed;
  top: 0;
  width: 100%;
  
}
