/*------------------------------------------------------------------------------
LOGIN PAGE */

#login-page
{ /* Parent container holding all login page content */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
}

.login-element 
{ /* container around login-components */
    width: 100%;
}
  
