:root {
    --border-radius-modal: 0.5rem;
    --border-radius-modal_50: 50%;
    --border-radius-modal_child: .25rem;
    --modal-parent-box-shadow: var(--box-shadow-default);
    --modal-top: var(--header-height);
    --modal-left: var(--navigation__aside-width);
    --modal-bottom: var(--footer-height);
}

/*TODO:: 082822 #EP || Add awareness of mobile or not to perform full screen vs not  */
/* :root {
    --modal-top: 0;
    --modal-left: 0;
    --modal-bottom: 0;
} */

.modal__parent{
    position: fixed;
    
    background-color: rgba(0, 0, 0, 0.6);
    /* width: calc(100% - var(--modal-left)); */
    /*TODO:: 10032022 #EP || DO I want this full screen or not? */
    width: calc(100%);
    height: calc(100% - var(--modal-top) - var(--modal-bottom) - .4rem);
    top: var(--modal-top);
    /* left: var(--modal-left); */
    left: 0;
    bottom: var(--modal-bottom);
    padding: 0;
    z-index: 999;
    /* box-shadow: var(--modal-parent-box-shadow); */
    /* padding: 2rem; */
    overflow: hidden;
    /* display: none; */
}

.modal__child {
    position: relative;
    /* border: var(--border-default-2px); */
    background: var(--clr-bdy-bg);
    /* height: calc(100% - var(--modal-bottom)); */
    height: 100%;
    width: 100%;
    /* border-radius: var(--border-radius-modal); */
    padding: 
            60px /* Pushing down to leave room for header */
            1rem
            0rem
            1rem;
    
    overflow-x: hidden;
    overflow-y: hidden;

    /* display: inline-flex; */
    /* flex-direction: column; */
    
    /* scrollbar-width: thin; */

    
    /* top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center; */
}

/* #hide-modal {
    display: none;
} */
.hide-modal {
    display: none;
}


/*----------------------------------------------------------------------------*/
/* Location Specific Control for Modal - Where it appears */
/*TODO:: Update UNIQUE STYLING OPTIONS THAT CAN BE ADDED */

.modal__right 
{ /* update parent padding with this */
    left: var(--modal-left);
    padding:
        calc( var(--header-height) + .5rem )
        1rem 
        /* calc(var(--height-footer) + .5rem ) */
        0rem
        calc(var(--width-sidebar) + 1rem );
        /* var(--width-sidebar) */
        /* 1rem */
        ;
}

/*TODO:: Add styling for a centered modal */
/* .modal__center {
    
}*/

/*TODO:: Add styling a tray style modal from bottom */
/* .modal__bottom {
    
}  */


/*----------------------------------------------------------------------------
MODAL HEADER - ( Top Right Hand Corner of Modal Element ) */

.modal__header{
    top: var(--modal-top);
    right: 1.5rem;
    position: fixed;
    justify-content: center;
    left: var(--modal-left);
    /* width: 100%; */
    /* margin: .5rem 1rem; */
    padding: 1rem 1rem 0 1rem;
    text-align: right;
    background-color: var(--clr-bg-3);
    z-index: 1;
    font-weight: var(--fw-regular);
    color:  var(--c-light-500);
    font-size: var(--fs-300);
    letter-spacing: var(--ls-700);
}

.modal__header h4 {
    font-size: var(--fs-600);
    letter-spacing: var(-- ls-500);
}

/*----------------------------------------------------------------------------
MODAL - SUB_HEADER - ( an available section that can be used as a second layer of heading to the left of the modal nav ) */

.modal__sub-header{
    top: var(--modal-top);
    left: calc(50px + 2rem);
    /* position: fixed; */
    justify-content: left;
    z-index: 1;
    padding: 0 0 1rem 0;
}

/*----------------------------------------------------------------------------
MODAL - SECTION - ( Primary Wrapper around a "page" or tab containing data witin modal ) */

.modal__section{
    /* flex: 100%; */
    /* width: 100%; */
    /* overflow-y: scroll; */
    /* height: 100vh; */
    
    max-height: calc(75vh); /* - var(--header-height) - var(--footer-height) ); (/)
    padding: 0;
    margin: 0;
    width: 100%;
    /* display: flex; */
    overflow-x: hidden;
    padding-bottom: 5rem;
    
}

.modal__page__section
{ /* Within a modal there are pages. within pages there are sections of data */

    width: 100%;
    background-color: inherit;
    position: sticky;
    top: 0;
    padding: 1rem 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.modal__page__section_toggle{
    position: sticky;
    top: 0;
    flex: 100%;
    width: 90%;
    /* background-color: var(--clr-bg-2); */
    /* border: var(--border-default-2px); */
    padding: .5rem 2rem;
    margin: 0 auto;
    border-radius: var(--border-radius-modal_child);
}

.modal__page__div
{ /* Within a modal there are pages. within pages there are sections of data.
    Within those sections there are DIVs */
    
    position: sticky;
    top: 0;

}


.modal__footer {
    /* Status bar at bottom of modal */
    border-top: var(--border-default-2px);
    max-height: 50px;
    position: absolute;
    bottom: 0rem;
    /* position: sticky; */
    /* bottom: 0rem; */
    left: 0;
    padding: .5rem;
    margin: auto;
    background-color: var(--clr-bg-3);
    width: 100%;
    /* width: 94.4%; */
    /* margin-left: .1rem; */
    font-size: var(--fs-500);
    color:  var(--clr-light-500);
    letter-spacing: var(--ls-700);
    text-transform: capitalize;
}


/*------------------------------------------------------------------------------
MODAL NAVIGATION COMPONENT                                                    */


/* All Navigational & Modal Control Elements  */
.modal__controls{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    
    position: sticky;
    z-index: 999999;
    top: 0;
    left: 0;
    
    margin: 0;
    padding: 0rem;
    
    
    /* Line below navigational modal controls to show split on pages  */
    border-bottom: var(--border-default-2px);

    /* width: fit-content; */
    /* top: 2rem;
    left: 10rem; */
    
    /* Were these used and needed for mobile? */
    /* top: calc(var(--modal-top) + .5rem ); */
    
    /* Needed to ensure overflow hides behind it. */
    background-color: var(--clr-bg-3);
    
    overflow-x: scroll;
    /* transition: all .3s ease-in-out; */
}



/*------------------------------------------------------------------------------
MODAL FOCUS - The primary focus for user in  modal.navigation component
*/

.modal__focus 
{ /* The topic of the modal. */
    
    display: flex;
    flex-direction: column;
    flex: 100%;
    /* align-items: center; */
    justify-content: left;
    /* grid-gap: .5rem; */

    position: sticky;
    
    /* Prevents overflow from being visible behind it as this sticks in location */
    background-color: var(--clr-bg-3);

    padding-bottom: .5rem;
    margin-bottom: .5rem;
}


/* Title and subTitle */
.modal__focus h5{
    font-size: var(--fs-800);
    font-weight: var(--fw-semi-bold);
    letter-spacing: var(--ls-500);
    max-height: calc(var(--fs-800) + 8px);
}

/* subSubTitle */
.modal__focus span {
    padding-left: .5rem;
    font-size: var(--fs-600);
    font-weight: var(--fw-regular);
    color: var(--clr-light-500);
    letter-spacing: var(--ls-700);
}



.modal__control {
    margin: .25rem .25rem .25rem .25rem;
    padding: .5rem .5rem;
    letter-spacing: var(--ls-500);
    /* width: 5rem; */
    border: 3px solid var(--clr-light-100);
    background-color: var(--clr-bg-2);
    min-width: fit-content;
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-500);
    /* transition: 100ms ease-in-out; */
    /* z-index: 1; */
}

.modal__control.active {
    border: 3px solid var(--clr-accent);
}

.modal__control:hover {
    border: 3px solid var(--clr-light-100);
}





/*------------------------------------------------------------------------------
Navigation Back in Modal Element */
.modal__navigate_back{
    display:flex;
    flex-direction: row;
    grid-gap: .5rem;

    z-index: 2;
    position: fixed;
    top: var(--modal-top); /*calc( var(--modal-top) );/*+ .25rem);*/
    /* left: calc(var(--modal-left) + .25rem); */
    
    color: var(--clr-light-900);
    font-size: var(--fs-600);
    font-weight: var(--fw-semi-bold);
    text-shadow: 1px 1px 3px var(--clr-dark-900);
    
    /* padding: 0 1rem 0 .5rem; */
    /* margin: 1rem 1.5rem; */
    padding: 0 1rem 0 0;
    margin: 1rem 1.5rem 1rem 0;
    
    
    /* border: 1px solid red; */
    border-radius: var(--border-radius-modal);
    transition: ease-in-out 200ms;
}

.modal__navigate_back:hover {
    background-color: var(--clr-dark-800);
    /* background-color: red; */
    cursor: pointer;
}

.modal__navigate_back:active {
    background-color: var(--clr-dark-500);
    color: var(--clr-light-700);

}

.modal__back_arrow{
    
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    
    padding: 0 .75rem 0 0;
    /* line-height: 20px; */
    font-size: 1.1rem;
    
    font-weight: var(--fw-bold);
}

.modal__path {
    display: flex;
    align-items: center;
    align-content: center;
    
    z-index: 2;
    /* position: fixed;
    top: calc(var(--modal-top) + 2px); 
    left: calc(var(--modal-left) + 5px + 50px); */
    
    min-height: 100%;
    /* margin: .5rem .25rem; */
}




.modal__close_x
{ /* Red X in top-right of container to close */
    position: absolute;
    top: .25rem;
    right: .25rem;
    
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    
    color: var(--clr-dark-100);
    background-color: var(--danger);
    border-radius: var(--border-radius-modal);
    
    
    font-size: 1.5rem;
    font-weight: 400;
    width: 20px;
    height: 20px;
    line-height: 20px;
    
    padding: 0 0 4px 0; /* push up a tiny-bit to center */
    margin: auto;
    
    opacity: .9;
    transition: ease-in-out 100ms;
}
.modal__close_x:hover{
    color: var(--clr-dark-300);
    opacity: 1;
}

.modal__close_x:active{
    color: var(--clr-dark-200);
    background-color: var(--danger_active);
 
}


@media screen and (max-width: 600px) {
    .modal__parent{
        height: calc(100% - var(--modal-top));
        bottom: 0;   
    }
    
    .modal__child{
        padding: 
            60px /* Pushing down to leave room for header */ 
            1rem 0rem 1rem;
        /* height: 90%; */
        /* overflow: auto; */   
    }

    .modal__header{
        width: 98%;
    }

    .modal__page {
        /* Primary wrapper within modal pages */
        max-height: 80%;
    }
    .modal__section{
        
        max-height: 80%;
    }

    .modal__footer {
        margin: 0;
        padding: .5rem;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
    }
}

/* .modal__section__table{

    margin: auto;
    flex: 100%;
    flex-direction: column;
    justify-content: space-between;
}


.modal__section__table tr{
    min-width: 100% !important;
    margin: auto;
    flex: 100%;
    min-width: 100px !important;
    justify-content: space-evenly;
}
.modal__section__table tc{
    border: 1px solid white;
    margin: auto;
    width: 100%;
    padding: 0 2rem;
    min-width: 100px !important;
} */


/* .modal__section__header, 
.modal__section__content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */

.modal__section__header{
    font-weight: var(--fw-bold);
    font-size: var(--fs-600);
    border-bottom: 1px solid var(--clr-light-100);
    margin-bottom: 1rem;
    padding: .5rem;
}

.modal__section__content {
    /* margin: auto; */
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 100%;
    /* width: 100%; */
}
.modal__section__content span{
    
    flex: 40%;
    justify-content: center;
    text-align: center;
}

.modal__section__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 100%;
    /* width: 100%; */
}

.modal__section__value{
    padding: .5rem;
    border: 1px solid var(--clr-light-100);
}