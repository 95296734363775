:root{

    --search-width: 250px;
    --search-button-width: 42px;
    
    --search-underline-width: calc( var(--search-width) - ( var(--search-button-width) * 2 ));

}

.search {
    position: relative;
    width: 250px;
    background-color: var(--clr-input-bg);
    display: flex;
    border: none;
    border-radius: var(--border-radius-input-parent);
    overflow: visible;
    height: 100%;
    /* padding: .25rem; */
}

/* Move label outside of search element */
.search:hover::before,
.search:active::before,
.search:focus::before,
.search:focus-within::before {
    transform: scale(1);
    background-color: var(--clr-bg-1);
}

/* UNDERLINED */
/* .search:before {
    content: "Search";
    position: absolute;
    left: var(--search-button-width);
    
    bottom: 0;
    width: var(--search-underline-width);
    height: 1px;
    background-color: var(--clr-light-200);
    transform: scaleX(0);
    transform-origin: center;
    transform-origin: left;
    transition: transform .1s;
    z-index: 1; 
} */

/* CONTENT MOVE TO TOP POP OUT */
.search:before {
    overflow: visible;
    content: attr(data-placeholder);
    position: absolute;
    
    left: var(--search-button-width);
    /* text-transform: uppercase; */
    font-size: var(--fs-100) !important;
    color: var(--clr-fnt-600);
    /* Move to middle of element */
    /* left: calc( var(--search-button-width) + (var(--search-underline-width ) / 2 ) ); */
    top: -17px;
    max-width: fit-content;
    max-height: fit-content;
    padding: 0;
    margin: 0;
    background-color: transparent !important;
    transform: scaleY(0);
    
    /* text-decoration: underline; */
    transform-origin: top;
    transition: transform 50ms;
    letter-spacing: var(--ls-700);
    z-index: 1; /* required to make appear otherwise behind element */
    /* background-color: red !important;
    border: 1px solid white; */
}

.search__input {
    /* border: none !important; */
    border: none;
    outline: none;
    border-radius: 0;
}

.search__button,
.clear__button,
.search__button svg g,
.clear__button svg g
{
    display: flex;
    place-items: center;
    background-color: inherit;
    cursor: pointer;
    stroke: var(--clr-fnt-600);
    transition: ease-in-out 200ms;
}

.search__button {
    border-radius: var(--border-radius-child-button) 0 0 var(--border-radius-child-button);
}
.clear__button {
    /* border-radius: 0 var(--border-radius-child) var(--border-radius-child) 0; */
    border-radius: 0 var(--border-radius-child-button) var(--border-radius-child-button) 0;
}

.search__button:hover svg g,
.clear__button:hover svg g
{
    stroke: var(--clr-fnt-1000);
    font-size: 1.1;
}

.search__input:hover,
.search__input:focus,
.search__input:focus-within
{
    border: 2px solid transparent !important;
    color: white;
}

.search:focus,
.search:focus-within
{
    background: var(--clr-bg-1);
}

.search__input:hover::placeholder,
.search__input:focus::placeholder {
    color: transparent;
    
}


/* No text yet so round the right corner of the input where the clear button will go */
.search .search__input:is(:placeholder-shown) {
    border-radius: 0 var(--border-radius-input-parent) var(--border-radius-input-parent) 0;
}

.search .search__input:is(:placeholder-shown) + .clear__button
{ /* HIDE the clear text if none yet */
    display: none !important;
    
}