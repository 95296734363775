
.clock{
    border-left: var(--border-default);
    font-weight: 300;
}


.clock:hover
{
    background-color: var(--bg-color-black-01);
}

.clock:hover
{ /* Keeps cursor from changing to select text pointer */
    cursor: default;
}

.clock {
    display: flex;
    align-items: center;
    justify-content: center; 
    font-size: .9rem;
    padding: .25rem 1rem;
    
}

/* @media screen and (max-width: 700px) {
    .clock {
        display: none;
    }
} */
