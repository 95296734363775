@import url(./normalize.css); /* Customize and resetting styling to simplify management of style */

@import url(./accessability.css); /* Reduced motion option */
@import url(./animations.css);  /* Universal Animation */
@import url(./input.css); /*TODO:: 07182022 #EP || Remove once inputs are components */
/* @import url(./page.css);   Universal Page Styling */
@import url(./theme.css); /* Color Theme including dark/light */

/*TODO:: 091122 #EP || Add rest of what's in helpers in description below */
@import url(./helpers.css); /* Padding, Margins, ...*/
@import url(./isLoading.css); /* Toggled off by JS once page is loaded to prevent pre-animations */

@import url(../../components/Card//assets//css//styles.css); /* Card Component */
/*@import url(./apple.css); /* Apple color theme */


/******************************************************************************/
:root { /* Variables to use inline */

  /* Prefer dark mode but second is light */
  /*TODO:: 05/21/22 #EP || Onboard color-schema */
  /* color-scheme: dark light;  */

    /* INPUT FORMATTING  */
  
  
  /* accent-color: var(--accent-color); */

  

  /* ALPHAS */
  --bg-color-black-01: rgba(0,0,0, .05);
  

  /* BORDERS */
  --border-default: 1px solid var(--systemGray);
  --border-transparent: 1px solid var(--clr-fnt-200);
  --border-default-1px: 1px solid var(--systemGray);
  --border-transparent-1px: 1px solid var(--clr-fnt-200);
  --border-default-2px: 2px solid var(--systemGray5);
  --border-transparent-2px: 2px solid var(--clr-fnt-200);
  
  /* BORDER RADIUS */
  --border-radius-default: 4px;
  --border-radius-parent: 10px;
  --border-radius-child: 2.5px;
  
  --border-radius-input-parent: 2.5em;
  --border-radius-child-button: 1em;

  --border-radius-tooltip: .3rem;


  /* SHADOWS */
  --box-shadow-default: .2rem .3rem .5rem .1rem rgba(0,0,0,.2); 
  --box-shadow-hover: .2rem .3rem .5rem .1rem rgba(0,0,0,.3); 

  
  
  
  /* COLORS */
  
  --clr-accent-400: hsl(12,88%, 59% );
  --clr-accent-100: hsl(13, 100%, 96%);

  --clr-primary-400: hsl(228, 39%, 23%);

  --clr-neutral-100: hsl(232, 12%, 13%);
  --clr-neutral-900: hsl(0, 0%, 0%);



  
  /* FONT FAMILY */
  --ff-primary: 'Roboto', sans-serif;

  --ff-body: var(--ff-primary);
  --ff-heading: var(--ff-primary);

  --flex-100: 10%;
  --flex-300: 30%;
  --flex-500: 50%;
  --flex-700: 70%;
  --flex-900: 90%;
  --flex-1000: 100%;


  /* FONT SIZE */
  --fs-100: .5rem;
  --fs-100: .7rem;
  --fs-200: .75rem;
  --fs-300: .8125rem;
  --fs-400: .875rem;
  --fs-500: .925rem;
  --fs-600: 1rem;
  --fs-650: 1.25em;
  --fs-700: 1.5rem;
  --fs-800: 2rem;
  --fs-900: 2.5rem;
  --fs-1000: 3rem;

  /* FONT WEIGHT */
  --fw-100: 100;
  --fw-200: 200;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;
  --fw-1000: 1000;
  
  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-semibold: 500;
  --fw-bold: 700;
  --fw-bolder: 900;

  /* LETTER SPACING */
  --ls-300: .5px;
  --ls-500: 1px;
  --ls-700: 2px;

  /* FONT SIZES */
  --fs-body: var(--fs-500);
  --fs-primary-heading: var(--fs-900);
  --fs-secondary-heading: var(--fs-700);
  --fs-nav: var(--fs-500);
  --fs-button: var(--fs-300);


  /* COLORS */
  --clr-dark-100: hsla(240, 3%, 12%, .1);
  --clr-dark-200: hsla(240, 3%, 12%, .2);
  --clr-dark-300: hsla(240, 3%, 12%, .3);
  --clr-dark-400: hsla(240, 3%, 12%, .4);
  --clr-dark-500: hsla(240, 3%, 12%, .5);
  --clr-dark-600: hsla(240, 3%, 12%, .6);
  --clr-dark-700: hsla(240, 3%, 12%, .7);
  --clr-dark-800: hsla(240, 3%, 12%, .8);
  --clr-dark-900: hsla(240, 3%, 12%, .9);
  --clr-dark-1000: hsla(240, 3%, 12%, 1.0);

  --clr-light-100: hsla(0, 0%, 100%, .1);
  --clr-light-200: hsla(0, 0%, 100%, .2);
  --clr-light-300: hsla(0, 0%, 100%, .3);
  --clr-light-400: hsla(0, 0%, 100%, .4);
  --clr-light-500: hsla(0, 0%, 100%, .5);
  --clr-light-600: hsla(0, 0%, 100%, .6);
  --clr-light-700: hsla(0, 0%, 100%, .7);
  --clr-light-800: hsla(0, 0%, 100%, .8);
  --clr-light-900: hsla(0, 0%, 100%, .9);
  --clr-light-1000: hsla(0, 0%, 100%, 1);

  --test: hsl(0, 0%, 70%);

  /*--------------------------------------------------------------------*/
  /* EVENT COLORS */
  --danger:   var(--systemRed);
  --danger-active: var(--systemRed2);
  --warning:  var(--systemOrange);
  --success:  var(--systemGreen);
  
  --info:     var(--systemBlue);
  
  --light:    var(--systemGray);
  --dark:     var(--systemGray2); 
  
  
  
  /*--------------------------------------------------------------------*/
  /* TRANSITIONS */
  --default-transition: ease-in-out .2s;
  
  /*--------------------------------------------------------------------*/
  /* ANIMATIONS */
}

/******************************************************************************/
@media (min-width: 50em) {
    :root{
      
        --fs-body: var(--fs-500);
        --fs-primary-heading: var(--fs-900);
        --fs-secondary-heading: var(--fs-800);
        --fs-nav: var(--fs-300);
    }
}




/*------------------------------------------------------------------------------
Normalizing */

/******************************************************************************/
body {
    font-family: var(--ff-body);
    color: var(--clr-bdy-fnt);
    accent-color: var(--accent-color);
    font-size: var(--fs-500);
    
    /* BACKGROUND  */
    background-color: var(--clr-bdy-bg);
    /* background-repeat: no-repeat; */
    /* background-attachment: fixed; */
    /* background-size: 100% 100%; */

    scroll-behavior: smooth;
    /* Disabling user selection */
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    line-height: 1.5;
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
      
}

/******************************************************************************/
#root { /* Container for the application excluding the header and footer. */
    height: calc( 100vh - var(--header-height) - var(--footer-height) );
}
  


/******************************************************************************/
/* Utility Classes */


.txt-primary-400        { color: var(--clr-primary-400); }
.txt-accent-400         { color: var(--clr-accent-400);  }
.text-accent-100        { color: var(--clr-accent-100);  }
.txt-neutral-100        { color: var(--clr-neutral-100); }
.txt-neutral-900        { color: var(--clr-neutral-900); }

.bg-primary-400         { background-color: var(--clr-primary-400); }
.bg-accent-400          { background-color: var(--clr-accent-400);  }
.bg-accent-100          { background-color: var(--clr-accent-100);  }
.bg-neutral-100         { background-color: var(--clr-neutral-100); }
.bg-neutral-900         { background-color: var(--clr-neutral-900); }

.fw-regular             { font-weight: var(--fw-regular); }
.fw-semi-bold           { font-weight: var(--fw-semi-bold); }
.fw-bold                { font-weight: var(--fw-bold); }

.fs-primary-heading     { font-size: var(--fs-primary-heading); }
.fs-secondary-heading   { font-size: var(--fs-secondary-heading); }

.fs-100                 { font-size: var(--fs-100); } 
.fs-300                 { font-size: var(--fs-300); } 
.fs-400                 { font-size: var(--fs-400); }
.fs-500                 { font-size: var(--fs-500); } /* Default */
.fs-600                 { font-size: var(--fs-600); } 
.fs-700                 { font-size: var(--fs-700); }
.fs-800                 { font-size: var(--fs-800); } 
.fs-900                 { font-size: var(--fs-900); }


/* TODO:: add p-y and p-x m-y and m-x border-b border border r border left */
.clr-fnt-100    { color: var(--clr-fnt-100);}
.clr-fnt-200    { color: var(--clr-fnt-200);}
.clr-fnt-300    { color: var(--clr-fnt-300);}
.clr-fnt-400    { color: var(--clr-fnt-400);}
.clr-fnt-500    { color: var(--clr-fnt-500);}
.clr-fnt-600    { color: var(--clr-fnt-600);}
.clr-fnt-700    { color: var(--clr-fnt-700);}
.clr-fnt-800    { color: var(--clr-fnt-800);}
.clr-fnt-900    { color: var(--clr-fnt-900);}
.clr-fnt-1000   { color: var(--clr-fnt-1000);}


.clr-light-100    { color: var(--clr-light-100);}
.clr-light-200    { color: var(--clr-light-200);}
.clr-light-300    { color: var(--clr-light-300);}
.clr-light-400    { color: var(--clr-light-400);}
.clr-light-500    { color: var(--clr-light-500);}
.clr-light-600    { color: var(--clr-light-600);}
.clr-light-700    { color: var(--clr-light-700);}
.clr-light-800    { color: var(--clr-light-800);}
.clr-light-900    { color: var(--clr-light-900);}
.clr-light-1000   { color: var(--clr-light-1000);}

.clr-dark-100    { color: var(--clr-dark-100);}
.clr-dark-200    { color: var(--clr-dark-200);}
.clr-dark-300    { color: var(--clr-dark-300);}
.clr-dark-400    { color: var(--clr-dark-400);}
.clr-dark-500    { color: var(--clr-dark-500);}
.clr-dark-600    { color: var(--clr-dark-600);}
.clr-dark-700    { color: var(--clr-dark-700);}
.clr-dark-800    { color: var(--clr-dark-800);}
.clr-dark-900    { color: var(--clr-dark-900);}
.clr-dark-1000   { color: var(--clr-dark-1000);}


.clr-fnt-danger         { color: var(--danger)};
.clr-fnt-danger-active  { color: var(--danger-active)};
.clr-fnt-warning        { color: var(--warning)};
.clr-fnt-success        { color: var(--success)};
.clr-fnt-info           { color: var(--info)}
.clr-fnt-info2          { color: var(--info2)}
.clr-fnt-light          { color: var(--light)}
.clr-fnt-dark           { color: var(--dark)}


/*------------------------------------------------------------------------------
Letter Spacing */

.ls-300                 { letter-spacing: var(--ls-300); }
.ls-500                 { letter-spacing: var(--ls-500); }
.ls-700                 { letter-spacing: var(--ls-700); }


/*------------------------------------------------------------------------------
FLEX */
.flex                   { display: flex; }
.flex-100               { flex: 10%; }
.flex-200               { flex: 20%; }
.flex-300               { flex: 30%; }
.flex-400               { flex: 40%; }
.flex-500               { flex: 50%; }
.flex-600               { flex: 60%; }
.flex-700               { flex: 70%; }
.flex-800               { flex: 80%; }
.flex-900               { flex: 90%; }
.flex-1000              { flex: 100%; }

/*------------------------------------------------------------------------------
GAP */

.gap-100                { gap: .1rem; }
.gap-200                { gap: .2rem; }
.gap-300                { gap: .3rem; }
.gap-400                { gap: .4rem; }
.gap-500                { gap: .5rem; }
.gap-600                { gap: .6rem; }
.gap-700                { gap: .7rem; }
.gap-800                { gap: .8rem; }
.gap-900                { gap: .9rem; }
.gap-1000               { gap: 1rem; }

/*------------------------------------------------------------------------------
OVERFLOW */
.overflow-auto   { overflow: auto; }
.overflow-hidden { overflow: hidden; }
.overflow-visible { overflow: visible; }
.overflow-scroll { overflow: scroll; }
.overflow-x-auto { overflow-x: auto; }
.overflow-x-hidden { overflow-x: hidden; }
.overflow-x-visible { overflow-x: visible; }
.overflow-x-scroll { overflow-x: scroll; }
.overflow-y-auto { overflow-y: auto; }
.overflow-y-hidden { overflow-y: hidden; }
.overflow-y-visible { overflow-y: visible; }
.overflow-y-scroll { overflow-y: scroll; }


/*------------------------------------------------------------------------------
POSITIONING */

.relative { position: relative; }
.absolute { position: absolute; }
.fixed    { position: fixed; }
.static   { position: static; }
.sticky   { position: sticky; }
.fixed-top { position: fixed; top: 0; }
.fixed-bottom { position: fixed; bottom: 0; }
.fixed-left { position: fixed; left: 0; }
.fixed-right { position: fixed; right: 0; }
.absolute-top { position: absolute; top: 0; }
.absolute-bottom { position: absolute; bottom: 0; }
.absolute-left { position: absolute; left: 0; }
.absolute-right { position: absolute; right: 0; }
.absolute-full { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
/* .absolute-full-top { position: absolute; top: 0; left: 0; right: 0; }
.absolute-full-bottom { position: absolute; bottom: 0; left: 0; right: 0; }
.absolute-full-left { position: absolute; left: 0; top: 0; bottom: 0; }
.absolute-full-right { position: absolute; right: 0; top: 0; bottom: 0; }
.absolute-full-center { position: absolute; top: 50%; left: 50%; right: 50%; bottom: 50%; }
.absolute-full-center-top { position: absolute; top: 0; left: 50%; right: 50%; }
.absolute-full-center-bottom { position: absolute; bottom: 0; left: 50%; right: 50%; }
.absolute-full-center-left { position: absolute; left: 0; top: 50%; bottom: 50%; }
.absolute-full-center-right { position: absolute; right: 0; top: 50%; bottom: 50%; }
.absolute-full-center-center { position: absolute; top: 50%; left: 50%; right: 50%; bottom: 50%; }
.absolute-full-center-center-top { position: absolute; top: 0; left: 50%; right: 50%; } */

.block{ display: block;}

.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.jc-center        { justify-content: center; }
.jc-space-between { justify-content: space-between; }
.jc-space-around  { justify-content: space-around; }
.jc-space-evenly  { justify-content: space-evenly}
.jc-flex-start    { justify-content: flex-start; }
.jc-flex-end      { justify-content: flex-end; }
.jc-left          { justify-content: left;}
.jc-right         {justify-content: right;}

.right{ 
  position: relative;
    float: right;
    right: 0;
    width: 100%;
    flex: 1
}
.left{ float: left;}

.m-a    { margin  : auto;}
.m-0    { margin  : 0;}
.m-100  { margin  : 0.1rem;}
.m-200  { margin  : 0.25rem;}
.m-300  { margin  : .50rem;}
.m-400  { margin  : .75rem;}
.m-500  { margin  : 1rem;}
.m-600  { margin  : 1.25rem;}
.m-700  { margin  : 1.5rem;}
.m-800  { margin  : 1.75rem;}
.m-900  { margin  : 2rem;}
.m-lr-0 { margin-left: 0; margin-right: 0; }



.width-1000,.w-1000 { width : 100%; }
/* TODO:: see how bootstrap does this */

.br-default { border-radius: var(--border-radius-default);}

.selectable {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.hidden { display: none;}

/*----------------------------------------------------------------------------*/

