.page__dropdown{
    position: relative;
    border-left: 2px solid var(--clr-fnt-100);
    border-right: 2px solid var(--clr-fnt-100);
    border-bottom: 2px solid var(--clr-fnt-100);
    border-radius: 0.5em;
    display: block;
    /* margin-top: 1rem; */
    /* margin-bottom: .5rem; */
}

.page__dropdown-label{
    display: block;
    padding: 1em;
    cursor: pointer;
}

.page__dropdown_button{
    /* margin: .25rem; */
    /* padding: 1.25rem 1rem; */
    letter-spacing: var(--ls-500);
    /* width: 5rem; */
    border: 2px solid var(--clr-light-100);
    background-color: var(--clr-bg-1);
    min-width: fit-content;
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-500);
    /* transition: 100ms ease-in-out; */
    /* z-index: 1; */
}

.page__dropdown_button.active{
    border: 2px solid var(--clr-accent) !important;
}



.page__dropdown-content{
    /* margin: 1em; */
    font-weight: 300;
    line-height: 1.5;
    display: block;
    /* margin-block-start: 1em;
    margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
 
}