/*------------------------------------------------------------------------------
    PADDING
 */

 .p-0     { padding: var(--rem-0)     !important;}
 .p-100   { padding: var(--rem-100)   !important;}
 .p-200   { padding: var(--rem-200)   !important;}
 .p-300   { padding: var(--rem-300)   !important;}
 .p-400   { padding: var(--rem-400)   !important;}
 .p-500   { padding: var(--rem-500)   !important;}
 .p-600   { padding: var(--rem-600)   !important;}
 .p-700   { padding: var(--rem-700)   !important;}
 .p-800   { padding: var(--rem-800)   !important;}
 .p-900   { padding: var(--rem-900)   !important;}
 .p-1000  { padding: var(--rem-1000)  !important;}

.p-x-0     { padding-left : var(--rem-0);     padding-right   : var(--rem-0)   ;}
.p-x-100   { padding-left : var(--rem-100);   padding-right   : var(--rem-100) ;}
.p-x-200   { padding-left : var(--rem-200);   padding-right   : var(--rem-200) ;}
.p-x-300   { padding-left : var(--rem-300);   padding-right   : var(--rem-300) ;}
.p-x-400   { padding-left : var(--rem-400);   padding-right   : var(--rem-400) ;}
.p-x-500   { padding-left : var(--rem-500);   padding-right   : var(--rem-500) ;}
.p-x-600   { padding-left : var(--rem-600);   padding-right   : var(--rem-600) ;}
.p-x-700   { padding-left : var(--rem-700);   padding-right   : var(--rem-700) ;}
.p-x-800   { padding-left : var(--rem-800);   padding-right   : var(--rem-800) ;}
.p-x-900   { padding-left : var(--rem-900);   padding-right   : var(--rem-900) ;}
.p-x-1000  { padding-left : var(--rem-1000);  padding-right   : var(--rem-1000);}

.p-l-0    { padding-left : var(--rem-0);   }
.p-l-100  { padding-left : var(--rem-100); }
.p-l-200  { padding-left : var(--rem-200); }
.p-l-300  { padding-left : var(--rem-300); }
.p-l-400  { padding-left : var(--rem-400); }
.p-l-500  { padding-left : var(--rem-500); }
.p-l-600  { padding-left : var(--rem-600); }
.p-l-700  { padding-left : var(--rem-700); }
.p-l-800  { padding-left : var(--rem-800); }
.p-l-900  { padding-left : var(--rem-900); }
.p-l-1000  { padding-left : var(--rem-1000); }

.p-r-0    {padding-right   : var(--rem-0);}
.p-r-100  {padding-right   : var(--rem-100);}
.p-r-200  {padding-right   : var(--rem-200);}
.p-r-300  {padding-right   : var(--rem-300);}
.p-r-400  {padding-right   : var(--rem-400);}
.p-r-500  {padding-right   : var(--rem-500);}
.p-r-600  {padding-right   : var(--rem-600);}
.p-r-700  {padding-right   : var(--rem-700);}
.p-r-800  {padding-right   : var(--rem-800);}
.p-r-900  {padding-right   : var(--rem-900);}
.p-r-1000  {padding-right   : var(--rem-1000);}


.p-y-0    { padding-top : var(--rem-0);    padding-bottom   : var(--rem-0);}
.p-y-100  { padding-top : var(--rem-100);  padding-bottom   : var(--rem-100);}
.p-y-200  { padding-top : var(--rem-200);  padding-bottom   : var(--rem-200);}
.p-y-300  { padding-top : var(--rem-300);  padding-bottom   : var(--rem-300);}
.p-y-400  { padding-top : var(--rem-400);  padding-bottom   : var(--rem-400);}
.p-y-500  { padding-top : var(--rem-500);  padding-bottom   : var(--rem-500);}
.p-y-600  { padding-top : var(--rem-600);  padding-bottom   : var(--rem-600);}
.p-y-700  { padding-top : var(--rem-700);  padding-bottom   : var(--rem-700);}
.p-y-800  { padding-top : var(--rem-800);  padding-bottom   : var(--rem-800);}
.p-y-900  { padding-top : var(--rem-900);  padding-bottom   : var(--rem-900);}
.p-y-1000  { padding-top : var(--rem-900);  padding-bottom   : var(--rem-900);}

.p-t-0    { padding-top : var(--rem-0);}
.p-t-100  { padding-top : var(--rem-100);}
.p-t-200  { padding-top : var(--rem-200);}
.p-t-300  { padding-top : var(--rem-300);}
.p-t-400  { padding-top : var(--rem-400);}
.p-t-500  { padding-top : var(--rem-500);}
.p-t-600  { padding-top : var(--rem-600);}
.p-t-700  { padding-top : var(--rem-700);}
.p-t-800  { padding-top : var(--rem-800);}
.p-t-900  { padding-top : var(--rem-900);}
.p-t-1000  { padding-top : var(--rem-1000);}

.p-b-0   { padding-bottom   : var(--rem-0);}
.p-b-100 { padding-bottom   : var(--rem-100);}
.p-b-200 { padding-bottom   : var(--rem-200);}
.p-b-300 { padding-bottom   : var(--rem-300);}
.p-b-400 { padding-bottom   : var(--rem-400);}
.p-b-500 { padding-bottom   : var(--rem-500);}
.p-b-600 { padding-bottom   : var(--rem-600);}
.p-b-700 { padding-bottom   : var(--rem-700);}
.p-b-800 { padding-bottom   : var(--rem-800);}
.p-b-900 { padding-bottom   : var(--rem-900);}
.p-b-1000 { padding-bottom   : var(--rem-1000);}
