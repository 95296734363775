.page__container
{ /* Parent object within a page */
    margin: 
        calc(var(--header-height))
        0
        0
        0;
    /* padding: 1rem 0 0 0; */
    height: 100%;
    overflow: hidden;
    
    display: flex;
    flex-direction: row;
    justify-content: left; /* Middle of page vertically */
    flex-wrap: wrap;
    /*align-items: center; /* Middle of page horizontally */
    align-items: flex-start;
    max-width: 100%; /* Make sure never fully edge-to-edge */
    
    /* transition: ease-in-out 200ms; */
    /* border: 1px solid red; */
    margin-left: calc(var(--navigation__aside-width) + .25rem);
    transition: ease-in-out 200ms;
}

.page__top 
{ /* Controlled by NAVIGATION to add padding here dynamically */
    margin-left:  .25rem;
    transition: ease-in-out 200ms;
    
}

.page__hero
{ /* Top container within a page that is meant to be as small as possible to identify the page and that's it.*/
    max-height: fit-content;
}

.page__header
{
    padding: 1rem 0;
    flex: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}


.page__header h2 {
    font-weight: 400;
    font-size: 2rem;
    letter-spacing: 1.5px;
    margin: 0;
    padding: 0;
}

.page__div
{ /* Secondary Page Wrapper around elements added to a Page - Features use this */
    
    display: flex;
    
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    
    width: 100%;
    height: 100%;
    
    overflow-y: scroll;

    padding: 1rem 1rem 0 .7rem;

    /* transition: ease-in-out 200ms; */
}

.page__section
{ /* Third wrapper around content within a page__div  */
    /* flex: 100%; */
    
    display: flex;
    
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    padding: 0;
    /* border: 1px solid red; */
    border-radius: var(--border-radius-default);
    border: var(--border-default-2px);
    
    width: 100%;
    /* background-color: var(--clr-bg-1); */
    background-color: rgba(255,255,255,0.02);
    
    /* transition: ease-in-out 200ms; */
}


.page__section__header
{ /* Page that contains title and summary */
    width:100%;
    height: 100%;
    flex: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* justify-content: flex-start; */
    justify-content: space-around;
    
    padding: 0;
    margin: 0;
    background-color: rgba(255,255,255,0.02);
}

.page__section__title {
    /* max-width:20%; */
    height: 100%;
    flex: 20% 1;
    display: flex;
    text-align: left;
    
    margin: auto 0 0 0;
    padding: .5rem;
    font-weight: 400;
}


.page__section__summary
{ /* if the section needs a summary to explain it or provide info this does that. */
    letter-spacing: .5px;
    max-width: 50ch;
    max-width: 400px;
    max-height: fit-content;
    padding: 0 0 .5rem 0;
    text-align: justify;
    margin: 0 .5rem;
    flex: 40% 4;
    font-size: .7rem;
}


/*------------------------------------------------------------------------------
LISTS  - Universal list styling within pages. */



.page__list__ul {
    position: relative;
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
/* border: 1px solid white; */
    width: 100%;
    height: 100%;
    padding: 0;
    
}


.page__list__li{
    padding: 0.75em;
    margin-bottom: 0.5em;
}

.page__list__li__controls {
    
}

.page__list__li,
.page__list__li__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: var(--border-default-2px);
    border-radius: var(--border-radius-default);
    background-color: var(--clr-accent);
    width: 90%;
    text-align: -webkit-match-parent;
}

.page__list__li>span {
    text-overflow: ellipsis;
    width: 100%;
    flex-grow: 1;
}

.page__list__li .action-icons{
    display: flex;
    align-items: center;
}

.page__list__li:first-child .action-icons button:first-child,
.page__list__ul li:nth-last-child(1) .action-icons button:nth-last-child(2) {
    opacity: .1;
    pointer-events: none;
}

li:first-child .action-icons button:first-child, .comparison-app li:nth-last-child(2) .action-icons button:nth-last-child(2){
    opacity: .1;
    pointer-events: none;
}

.page__list__ul .action-icons button {
    appearance: none;
    border: 0;
    padding: 0;
    background: transparent;
    border-radius: 0;
    margin-left: 0.75em;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.page__list__ul li svg {
    width: 20px;
    fill: var(--clr-bg-1);
}

.page__list__ul li svg.down {
    transform: rotate(180deg);
    fill: var(--clr-bg-1);
}

.page__list__ul .action-icons .remove svg {
    fill: red;
    

}


.page__list
{ /* If a page needs to list content it's going to use this wrapper */

    text-rendering: optimizeSpeed;
    line-height: 1.5;

   
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    justify-content: center;
    align-items: flex-start;
    
    grid-gap: 1rem;

    margin: auto;
    width: 100%;
    height: 100%;
    /* padding: 5% 0 0% 0; */
    /* margin-bottom: 10rem; */

    /* transition: ease-in-out 200ms; */
}

.page__list__controls
{ /* bar at the top of a list to manage content */
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    margin: auto;
    align-items: center;
    gap: .25rem;
    padding: .25rem;
    
}
.page__list_controls_element
{ /* Each element within the control bar */
    display: flex;
    align-items: center;
    border: 1px solid red;
    width: 100%;
    height: 1rem;
    justify-content: center;
    border-radius: none;
}



.page__list__item
{ /* This is the wrapper around each item within the list
     If a page has a list, the items within will use this class 
    */
    
    position: relative;
    
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    /* padding: 1rem; */
    /* margin: 0 1rem; */
    flex: 100%;
    width: 100%;
    height: 100%;
    font-size: .8rem;
    line-height: 1.5;
    
    overflow: hidden;
    
    /* border: 1px solid var(--systemGray); */
    border-top: var(--border-default);
    /* border-bottom: var(--border-default); */
    
    /* border-radius: var(--border-radius-default); */

    /* transition: ease-in-out 200ms; */
}


.page__list__item_ul
{ /* All items within the list are contained within this UL */
    align-items: left;
    text-align: left;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    list-style: none;
}

.page__list__item_li li
{
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
}

.page__list__item_li_header {
    /* width: 100%; */
    flex: 50% 1; 
}    

.page__list__item_li_header_title
{
    top: 0;
    left: 0;
    position: absolute;
    align-items: left;
    text-align: left;
    padding: 0 .5rem;
    margin-top: 0;
    width: 100%;
}



@media screen and (max-width: 600px) {
    .page__div{
        padding: 1rem;
        /* top: 1rem; */
        height: calc(100% - 60px);
    }


}