.container__sensors
{ /* Main Wrapper around Sensors Page */
    
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start; */

    /* transition: ease-in-out 200ms; */
    /* padding: 2rem; */
    
    /* overflow: none; */
}


/* Wrapper around header */
.sensors__section
{
    flex: 100%;
    
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    margin-bottom: 1rem;
    padding: 1rem;
    /* border: 1px solid red; */
    border-radius: var(--border-radius-default);
    border: var(--border-default);
    width: 100%;
}

.sensors__div
{
    flex: 100%;
    padding: .5rem 1rem;
    width: 100%;
    border-radius: var(--border-radius-default);
    border: var(--border-default);
}



/* Wrapper around all sensors */
.sensors__list {
    text-rendering: optimizeSpeed;
    line-height: 1.5;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    justify-content: center;
    align-items: flex-start;
    
    grid-gap: 1rem;

    margin: auto;
    width: 100%;
    height: 100%;
    /* padding: 5% 0 0% 0; */
    /* margin-bottom: 10rem; */

    transition: ease-in-out 200ms;
}


.container__sensor
{ /* Wrapper around each Sensor */
    
    position: relative;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 2rem 0;
    margin:  2rem 0;
    flex: 100%;
    width: 100%;
    height: 100%;
    font-size: .8rem;
    line-height: 1.5;
    
    overflow: hidden;
    
    /* border: 1px solid var(--systemGray); */
    border-top: var(--border-default);
    /* border-bottom: var(--border-default); */
    
    /* border-radius: var(--border-radius-default); */

    transition: ease-in-out 200ms;
}    


.container__sensor ul
{
    align-items: left;
    text-align: left;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}
.container__sensor li
{
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
}

.container__sensor__header {
    /* width: 100%; */
    flex: 50% 1;
    
}    

.container__sensor__header__title
{
    top: 0;
    left: 0;
    position: absolute;
    align-items: left;
    text-align: left;
    padding: 0 .5rem;
    margin-top: 0;
    width: 100%;
}    



.sensor__img {
    max-width: 150px;
    max-height: 150px;
    
}

.sensor__stats
{ /* Overriding page__section forced padding on bottom */
    padding: .5rem 2rem !important;
    margin: 0 !important;
    flex: 100% 5;
}

.sensor__stats__status{
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255,255,255,0.1);
    border: 2px solid rgba(255,255,255,0.1);
    border-radius: 0 0 0 .5rem;
    padding: 0 .5rem;
}

.sensor__data{
    position: absolute;
    bottom: 0rem;
    right: 0;
    /* background-color: rgba(255,255,255,0.1); */
    /* border: 2px solid rgba(255,255,255,0.1); */
    border-radius: 0 0 .5rem 0;
}