:root {
    --navigation__aside-width: 75px;
    --width-sidebar: var(--navigation__aside-width);
    --navigation__top-height: var(--header-height);
    --navigation__aside-bg: hsla(233, 13%, 16%, 1.0);
    --navigation__aside-bdr: var(--clr-fnt-800);
}

nav {
    z-index: 2;
    background-color: var(--clr-header-bg);
    color: var(--clr-header-fnt);
    font-weight: lighter;
    font-size: 0.85rem;
    
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
    /* transition: ease-in-out .2s; */
}

nav::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

nav a{
    color: var(--clr-header-a);
    text-decoration: none;
}

.navigation__top
{ /* Navigation is on the TOP of the page */
    
    
    display: flex;
    flex-direction: row;
    text-decoration: none;
    /* justify-content: right; */
    justify-content: flex-end;
    margin: auto;
    position: fixed;
    top: 0;
    right: 150px;
    /* width: 75%; */
    height: var(--navigation__top-height);
    z-index: 3;
    background: none;
}


.navigation__aside{
    /* Navigation is on the TOP of the page */
    
    
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    
    overflow-y: scroll; 
    overflow-x: hidden;
    
    
    width: var(--navigation__aside-width);
    top: var(--f-height);
    height: calc(100% - var(--header-height) - var(--footer-height));
    /* padding:  calc(var(--header-height))  0; */
    padding: .25rem 0;
    gap: 2rem;
    
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
    /* justify-content: start; */
    align-self: flex-start;
    /* justify-content: align-self; */
    background-color: var(--navigation__aside-bg);
}


element::-webkit-scrollbar 
{ /* Hides scrollbar inline navigation aside */
    display: none; /* for Chrome, Safari, and Opera */
}

.navigation__link{
    /* A href link to location */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto; /* Pushes to center */ 
    vertical-align: center;
    
    letter-spacing: 1px;
    font-size: var(--fs-100);
    font-weight: var(--fw-semi-bold);
    
    /* Moves to bottom of navigation aside bar */
    margin-top: auto;
    transition: ease-in-out .2s;
    color: var(--clr-fnt-1000);
}
.navigation__aside .navigation__link:first-child{
    margin-top: 1rem;
}
.navigation__aside .navigation__link:last-child{
    /* Moves to bottom of menu */
    margin-top: auto;
    margin-bottom: 2rem;
}

.navigation__pageName{
    /* Page name is the name of the page below the icon when not active. */

    position: absolute;
    opacity: .8;
    bottom: -3px;
    /* animation-delay: 1s; */
    transition: ease-in-out 100ms;
    
}

.navigation__icon{
    position: relative;
    height: 100%;
    
    /* For Safari 3.1 to 6.0 */
    -webkit-transition: ease-in-out .1s; 
    transition: ease-in-out .1s;
}

.navigation__aside .navigation__link:hover .navigation__icon{
    height: 100%;
}
.navigation__aside .navigation__link:hover .navigation__pageName{
    /* When hovering an icon in navigation, slides page-name up */
    opacity: 1;
    bottom: 0px;
}
.navigation__link.link__active .navigation__pageName{
    opacity: .9;
    /* top: 5px; */
    bottom: -1px;
}

/* .navigation__link:hover
{
    border-bottom: 2px solid rgba(255,255,255, .8);
    background-color:rgba(0,0,0, .2);    
}
 */
.navigation__aside .navigation__link:hover {
    cursor: pointer;
    border-right: 2px solid var(--clr-fnt-200);
    color: var(--clr-fnt-900);
    /* background-color: var(--clr-bg-2); */
    
}

.navigation__top .navigation__link{
    display: flex;
    align-items: center;
    margin: auto ;
    height: 100%;
    padding: 0 1rem; /* Makes sure space between */
    border-bottom: 2px solid transparent;
}

.navigation__top .navigation__link.link__active{
    /* Top navigation underlines element if active */
    /* border-bottom: 2px solid var(--navigation__aside-bdr); */
    /* font-weight: var(--fw-semi-bold); */
    
    
}

.navigation__aside .navigation__link {
    /* For links specifically in the aside navigation element, update styling*/
    /* border-left: 2px solid transparent; */
    margin: 0rem auto;
    padding: .5rem 0 1.25rem 0;
    width: 100%;
    text-align: center;
    align-content: center;
    border-bottom: none;
    border-right: 2px solid transparent;
    /* color: var(--clr-fnt-100); */
    /* height: 100%; */
    
}

.navigation__aside .navigation__link.link__active{
    /* The navigational element that is selected within aside navigation */
    font-weight: var(--fw-semi-bold);
    color: var(--clr-fnt-1000);
    letter-spacing: var(--ls-500);
    background-color: rgba(0,0,0, .2);
    border-top: 2px solid rgba(0,0,0 , .1);
    border-bottom: 2px solid rgba(0,0,0 , .1);
    min-height: fit-content;
}

/* TODO:: 07262022 #EP || Either merge header navigation elements into header or segment this code another way. */
.header__menu {
    gap: .25rem;
}

.header__menu .navigation__link {
    border: 1px solid transparent;
    border-radius: var(--border-radius-default);
}

.header__menu .navigation__link.link__active {
    /* The navigational element that is selected within header navigation */
    border: 1px solid rgba(255,255,255, .1);
    border-radius: var(--border-radius-default);
    box-shadow: inset 0px 0px 1px 1px rgba(0,0,0,.2);
    background-color: rgba(0,0,0, .05);
}
.header__menu .navigation__link:hover {
    /* Customizing styling for header elements */
    /* border-bottom: 2px solid rgba(255,255,255, .1); */
    
    border: 1px solid rgba(255,255,255, .2);
    border-radius: var(--border-radius-default);
}


.nav__alert_count{
    /* Container holding Alerts / Notifications count in NAV */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    position: absolute;
    top: .3rem;
    right: 10%;
    /* border: 1px solid var(--clr-fnt-100); */
    padding: .5rem .25rem;
    
    min-width: 15px;
    max-height: 15px;

    border-radius: .5rem;
    /* margin: auto; */
    /* align-items: center;
    text-align: center; */
    
    background-color: var(--danger-active);
    border: 1px solid var(--danger-active);
    color: var(--clr-fnt-1000);

    font-weight: var(--fw-bold);
    font-size: var(--fs-200);
}


.navigation__link:hover .nav__alert_count{
    /* When hovering over navigation, make notification count bg brighter */
    /* background-color: var(--danger-active); */
}


/*----------------------------------------------------------------------------*/
/* MEDIA QUERIES */


/* Disable side-bar navigation and move to bottom of screen for mobile friendly design*/
@media screen and (max-width: 600px) {
    :root {
        /* Setting to zero as it's no longer an aside nav*/
        --navigation__aside-width: 0px;
    }
    .navigation__aside{
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        
        width: 100%;
        
        /* Unset to remove formatting that's killing it */
        top: unset;
        bottom: 0;
        /* height: fit-content; */
        height: 80px;
        /* height: calc(100% - var(--header-height) - var(--footer-height)); */
        /* padding:  calc(var(--header-height))  0; */
        /* padding: 0 0 .25rem; */
        padding: 0;
        gap: 0;
        
        position: fixed;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        /* justify-content: space-evenly; */
        /* justify-content: start; */
        align-self: flex-start;
        /* justify-content: align-self; */
        background-color: var(--navigation__aside-bg);
        /* border-top: 1px solid rgba(0,0,0, .2); */
        box-shadow: 0 0 0px 1px rgba(0,0,0 , .2);

        overflow: hidden;
    }

    /* .navigation__aside .navigation__link:first-child {
        margin-top: unset;
    }
    .navigation__aside .navigation__link:last-child {
        margin-bottom: unset;
    } */

    
    
    
    
    .navigation__aside .navigation__link,
    .navigation__aside .navigation__link:first-child,
    .navigation__aside .navigation__link:last-child {
        height: 100%;
        /* margin: .5rem auto 1rem;
        padding: .5rem 0 1.5rem; */
        margin:  0 auto 1rem;
        padding: .3rem  0 1.5rem;
        
        background: var(--navigation__aside-bg);
        border-radius: 10px;
        /* padding: 1.5rem 0 1.25rem; */
        /* margin: 1rem auto; */
        flex: auto;
        /* width: unset; disable take full-row */
        min-width: 90px;
        max-width: 90px;
        min-height: 70px;
        max-height: 70px;
        
        font-size: var(--fs-100);
        letter-spacing: 1px;
        font-weight: var(--fw-semi-bold);
        
        background-color: rgba(0,0,0, .05);
        border: 2px solid rgba(0,0,0 , .1);
    }

    .navigation__aside .navigation__link.link__active{
        border: unset;
        /* top: unset; */
        /* font-weight: var(--fw-bold); */
        border: 2px solid rgba(0,0,0 , .2);
        background-color: rgba(0,0,0,.2);
    }

    .navigation__aside .navigation__link:hover {
        border-right: unset;
        border: 2px solid rgba(0,0,0, 0.2);
        background-color: rgba(0,0,0,.1);
    }
    .navigation__aside .navigation__link:active {
        border: 2px solid rgba(0,0,0, 0.2);
        background-color: rgba(0,0,0,.3);
    }

    .navigation__aside .navigation__link:hover .navigation__icon{
        height: 100%;
    }

    .navigation__pageName{
        /*The page Display Name */
        position: absolute;
        opacity: .7;
        bottom: 15px;
        /* animation-delay: 1s; */
        transition: ease-in-out 100ms;
        
    }

    .navigation__link.link__active .navigation__pageName{
        opacity: 1;
        /* top: 5px; */
        bottom: 16px;
    }
    .navigation__aside .navigation__link:hover .navigation__icon{
        padding-top: 0px;
    }
    .navigation__aside .navigation__link:hover .navigation__pageName{
        /* When hovering an icon in navigation, slides page-name up */
        bottom: 18px;
    }

    footer{
        /* TODO:: 091822 #EP || change this to actual footer. For now here for ez mgmt and testing*/
        display: none !important;
    }
}


