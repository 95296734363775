:root {
    --logo-width: 25px;  /* Defines proper size of bear */
    --logo-height: 25px; /* Fitting in container vertically otherwise view-box stretches height */
}

.logo
{
    max-width: var(--logo-width);
    max-height: var(--logo-height);
    filter: drop-shadow(1px 1px .4px rgb(41 24 60 / 0)); /* basic shadow on SVG */
    fill: black;
    /* transition: ease-in-out 200ms; */
    

}
