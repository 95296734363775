:root {
    --rem-0     : 0;
    --rem-100   : .1rem;
    --rem-200   : .25rem;
    --rem-300   : .5rem;
    --rem-400   : .75rem;
    --rem-500   : 1rem;
    --rem-600   : 1.25rem;
    --rem-700   : 1.5rem;
    --rem-800   : 1.75rem;
    --rem-900   : 2rem;
    --rem-1000  : 4rem;

}

@import url(./padding.css);
@import url(./margins.css);

