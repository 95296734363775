/* Default Root  */
/* :root { */
    /*TODO:: 07182022 #EP || Verify this is being used by default if dark/light not being used */
    /* 05/22/22 #EP || Extracted from --> https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/color/ */
    
    /* APPLE GRAY - LIGHT THEME  */
    /* --lt_systemGray: rgb(142, 142, 147);
    --lt_systemGray2: rgb(174, 174, 178);
    --lt_systemGray3: rgb(199, 199, 204); 
    --lt_systemGray4: rgb(209, 209, 214); 
    --lt_systemGray5: rgb(229, 229, 234); 
    --lt_systemGray6: rgb(242, 242, 247);  */


    /* APPLE COLORS - LIGHT THEME */
    /* --lt_systemRed: rgb(255, 59, 48);
    --lt_systemOrange: rgb(255, 149, 0);
    --lt_systemYellow: rgb(255, 204, 0);
    --lt_systemGreen: rgb(52, 199, 89);
    --lt_systemMint: rgb(0, 199, 190);
    --lt_systemTeal: rgb(48, 176, 199);
    --lt_systemCyan: rgb(50, 173, 230);
    --lt_systemBlue: rgb(0, 122, 255);
    --lt_systemIndigo: rgb(88, 86, 214);
    --lt_systemPurple: rgb(175, 82, 222);
    --lt_systemPink: rgb(255, 45, 85);
    --lt_systemBrown: rgb(162, 132, 94); */
    

    /* APPLE COLORS - DARK THEME  */
    /* --drk_systemMint: rgb(102, 212, 207);
    --drk_systemRed: rgb(255, 69, 58);
    --drk_systemOrange: rgb(255, 159, 10);
    --drk_systemYellow: rgb(255, 214, 10);
    --drk_systemGreen: rgb(48, 209, 88);
    --drk_systemTeal: rgb(64, 200, 224);
    --drk_systemCyan: rgb(100, 210, 255);
    --drk_systemBlue: rgb(10, 132, 255);
    --drk_systemIndigo: rgb(94, 92, 230);
    --drk_systemPurple: rgb(191, 90, 242);
    --drk_systemPink: rgb(255, 55, 95);
    --drk_systemBrown: rgb(172, 142, 104); */
    
    /* APPLE GRAY - DARK THEME  */
    /* --drk_systemGray: rgba(142, 142, 147);
    --drk_systemGray2: rgb(99, 99, 102);
    --drk_systemGray3: rgb(72, 72, 74); 
    --drk_systemGray4: rgb(58, 58, 60); 
    --drk_systemGray5: rgb(44, 44, 46); 
    --drk_systemGray6: rgb(28, 28, 30); */


/* } */

/* :root { */
    /* --clr-dark-100: hsla(240, 3%, 12%, .1);
    --clr-dark-200: hsla(240, 3%, 12%, .2);
    --clr-dark-300: hsla(240, 3%, 12%, .3);
    --clr-dark-400: hsla(240, 3%, 12%, .4);
    --clr-dark-500: hsla(240, 3%, 12%, .5);
    --clr-dark-600: hsla(240, 3%, 12%, .6);
    --clr-dark-700: hsla(240, 3%, 12%, .7);
    --clr-dark-800: hsla(240, 3%, 12%, .8);
    --clr-dark-900: hsla(240, 3%, 12%, .9);
    --clr-dark-1000: hsla(240, 3%, 12%, 1.0);

    --clr-light-100: hsla(0, 0%, 100%, .1);
    --clr-light-200: hsla(0, 0%, 100%, .2);
    --clr-light-300: hsla(0, 0%, 100%, .3);
    --clr-light-400: hsla(0, 0%, 100%, .4);
    --clr-light-500: hsla(0, 0%, 100%, .5);
    --clr-light-600: hsla(0, 0%, 100%, .6);
    --clr-light-700: hsla(0, 0%, 100%, .7);
    --clr-light-800: hsla(0, 0%, 100%, .8);
    --clr-light-900: hsla(0, 0%, 100%, .9);
    --clr-light-1000: hsla(0, 0%, 100%, 1); */
/* } */

/*------------------------------------------------------------------------------
COLOR SCHEME FOR DARK OR LIGHT */

/* DARK MODE COLORING */
@media screen and (prefers-color-scheme: dark) {
    :root {
        /*--------------------------------------------------------------------*/
        /* APPLE COLORS - DARK THEME  */
        --systemMint: rgb(102, 212, 207);
        /* --systemRed2: rgb(255, 69, 58); */
        --systemRed2: hsla(0, 99%, 62%, 1.0);
        --systemRed: hsla(3, 99%, 67%, 1.0);
        --systemOrange: rgb(255, 159, 10);
        --systemYellow: rgb(255, 214, 10);
        --systemGreen: rgb(48, 209, 88);
        --systemTeal: rgb(64, 200, 224);
        --systemCyan: rgb(100, 210, 255);
        --systemBlue: rgb(10, 132, 255);
        --systemIndigo: rgb(94, 92, 230);
        --systemPurple: rgb(191, 90, 242);
        --systemPink: rgb(255, 55, 95);
        --systemBrown: rgb(172, 142, 104);
        
        /* APPLE GRAY - DARK THEME  */
        --systemGray: rgba(142, 142, 147);
        --systemGray2: rgb(99, 99, 102);
        --systemGray3: rgb(72, 72, 74); 
        --systemGray4: rgb(58, 58, 60); 
        --systemGray5: rgb(44, 44, 46); 

        /* APPLE BACKGROUNDS */
        --clr-bg-1: hsl(229, 14%, 16%);
        --clr-bg-2: hsla(210, 21%, 10%, 1.0);
        --clr-bg-3: hsl(210, 22%, 7%); /* Bunker - Main Background Color -  #0E1216 */
        --clr-bg-4: hsl(240, 3%, 12%); /* Shark- Unused - #1d1d1f */
        --clr-bg-500: hsla(240, 3%, 12%, .9); /* Shark- Unused - #1d1d1f */
        
        
        /* FONT */
        --clr-fnt-1: hsla(0, 0%, 100%, 1.0);
        --clr-fnt-2: #1d1d1f;

         /* FONT */
         --clr-fnt-100: hsla(0, 0%, 100%, .1);
         --clr-fnt-200: hsla(0, 0%, 100%, .2);
         --clr-fnt-300: hsla(0, 0%, 100%, .3);
         --clr-fnt-400: hsla(0, 0%, 100%, .4);
         --clr-fnt-500: hsla(0, 0%, 100%, .5);
         --clr-fnt-600: hsla(0, 0%, 100%, .6);
         --clr-fnt-700: hsla(0, 0%, 100%, .7);
         --clr-fnt-800: hsla(0, 0%, 100%, .8);
         --clr-fnt-900: hsla(0, 0%, 100%, .9);
         --clr-fnt-1000: var(--clr-fnt-1);
        /*--------------------------------------------------------------------*/

        --clr-header-bg: var(--systemBlue);
        --clr-header-fnt: var(--clr-fnt-1);
        --clr-header-a: var(--clr-fnt-1);
        
        --clr-bdy-bg: var(--clr-bg-3);
        --clr-bdy-fnt: #fefefe;

        
        --clr-accent: var(--systemBlue);    
        accent-color: var(--clr-accent);

        --clr-input-bdr: #5299c3;
        --clr-input-fcs: hsla(218, 89%, 50%, 1.0);
        --clr-input: hsla(218, 89%, 50%, .9);
        --clr-input-bg: var(--systemGray5);
        --clr-input-fnt: var(--clr-fnt-1);

        --clr-accent-fcs: hsla(218, 89%, 50%, 1.0);



        /*--------------------------------------------------------------------*/
        /* EVENT COLORS */
        --danger: var(--systemRed);
        --danger-active: var(--systemRed2);
        --warning: var(--systemOrange);
        --success: var(--systemGreen);
        --info: var(--systemBlue);
        --general: var(--systemGray2);
        --general: var(--clr-bg-1);
        --light: var(--systemGray);
        --dark: var(--systemGray2);   
    }
}

/* LIGHT MODE COLORING */
@media screen and (prefers-color-scheme: light) {
    :root {
        
        /*--------------------------------------------------------------------*/
        /* APPLE COLORS - LIGHT THEME */
        /* --systemRed2: rgb(255, 59, 48); */
        --systemRed2: hsla(0, 99%, 62%, 1.0);
        --systemRed: hsla(3, 99%, 67%, 1.0);
        --systemOrange: rgb(255, 149, 0);
        --systemYellow: rgb(255, 204, 0);
        --systemGreen: rgb(52, 199, 89);
        --systemMint: rgb(0, 199, 190);
        --systemTeal: rgb(48, 176, 199);
        --systemCyan: rgb(50, 173, 230);
        --systemBlue: rgb(0, 122, 255);
        --systemIndigo: rgb(88, 86, 214);
        --systemPurple: rgb(175, 82, 222);
        --systemPink: rgb(255, 45, 85);
        --systemBrown: rgb(162, 132, 94);
    
        --systemGray: rgb(142, 142, 147);
        --systemGray2: rgb(174, 174, 178);
        --systemGray3: rgb(199, 199, 204); 
        --systemGray4: rgb(209, 209, 214); 
        --systemGray5: rgb(229, 229, 234); 
        --systemGray6: rgb(242, 242, 247); 

        /* APPLE BACKGROUND */
        --clr-bg-1: #fafafa;
        --clr-bg-2: #ffffff;
        --clr-bg-3: #f3f3f3;

        /* FONT */
        --clr-fnt-1: hsla(240, 3%, 12%, 1.0);
        --clr-fnt-100: hsla(240, 3%, 12%, .1);
        --clr-fnt-200: hsla(240, 3%, 12%, .2);
        --clr-fnt-300: hsla(240, 3%, 12%, .3);
        --clr-fnt-400: hsla(240, 3%, 12%, .4);
        --clr-fnt-500: hsla(240, 3%, 12%, .5);
        --clr-fnt-600: hsla(240, 3%, 12%, .6);
        --clr-fnt-700: hsla(240, 3%, 12%, .7);
        --clr-fnt-800: hsla(240, 3%, 12%, .8);
        --clr-fnt-900: hsla(240, 3%, 12%, .9);
        --clr-fnt-1000: var(--clr-fnt-1);

        /*--------------------------------------------------------------------*/
        --clr-primary: #202302;
        --clr-secondary: #00100d;
        --clr-accent: var(--systemBlue);

        /* HEADER */
        --clr-header-bg: var(--systemBlue);
        --clr-header-fnt: var(--clr-fnt-1);
        --clr-header-a: var(--clr-fnt-1);
        /* BODY */
        --clr-bdy-bg: var(--clr-bg-3);
        --clr-bdy-fnt: var(--clr-fnt-1);

        /* ACCENT COLOR */
        accent-color: var(--clr-accent);

        /* INPUTS */
        --clr-input-bdr: #5299c3;
        --clr-input-fcs: hsla(218, 89%, 50%, 1.0);
        --clr-input: hsla(218, 89%, 50%, .9);
        --clr-input-bg: #FFFFFF;
        --clr-input-fnt: var(--clr-fnt-1);

    }
  }

@media screen and (prefers-color-scheme: light) {

    :root {
        /*--------------------------------------------------------------------*/
        /* APPLE COLORS - DARK THEME  */
        --systemMint: rgb(102, 212, 207);
        /* --systemRed2: rgb(255, 69, 58); */
        --systemRed2: hsla(0, 99%, 62%, 1.0);
        --systemRed: hsla(3, 99%, 67%, 1.0);
        --systemOrange: rgb(255, 159, 10);
        --systemYellow: rgb(255, 214, 10);
        --systemGreen: rgb(48, 209, 88);
        --systemTeal: rgb(64, 200, 224);
        --systemCyan: rgb(100, 210, 255);
        --systemBlue: rgb(10, 132, 255);
        --systemIndigo: rgb(94, 92, 230);
        --systemPurple: rgb(191, 90, 242);
        --systemPink: rgb(255, 55, 95);
        --systemBrown: rgb(172, 142, 104);
        
        /* APPLE GRAY - DARK THEME  */
        --systemGray: rgba(142, 142, 147);
        --systemGray2: rgb(99, 99, 102);
        --systemGray3: rgb(72, 72, 74); 
        --systemGray4: rgb(58, 58, 60); 
        --systemGray5: rgb(44, 44, 46); 

        /* APPLE BACKGROUNDS */
        --clr-bg-1: hsl(229, 14%, 16%);
        --clr-bg-2: hsla(210, 21%, 10%, 1.0);
        --clr-bg-3: hsl(210, 22%, 7%); /* Bunker - Main Background Color -  #0E1216 */
        --clr-bg-4: hsl(240, 3%, 12%); /* Shark- Unused - #1d1d1f */

        /* FONT */
        --clr-fnt-1: hsla(0, 0%, 100%, 1.0);
        --clr-fnt-2: #1d1d1f;

        /* FONT */
        --clr-fnt-100: hsla(0, 0%, 100%, .1);
        --clr-fnt-200: hsla(0, 0%, 100%, .2);
        --clr-fnt-300: hsla(0, 0%, 100%, .3);
        --clr-fnt-400: hsla(0, 0%, 100%, .4);
        --clr-fnt-500: hsla(0, 0%, 100%, .5);
        --clr-fnt-600: hsla(0, 0%, 100%, .6);
        --clr-fnt-700: hsla(0, 0%, 100%, .7);
        --clr-fnt-800: hsla(0, 0%, 100%, .8);
        --clr-fnt-900: hsla(0, 0%, 100%, .9);
        --clr-fnt-1000: var(--clr-fnt-1);
        /*--------------------------------------------------------------------*/

        --clr-header-bg: var(--systemBlue);
        --clr-header-fnt: var(--clr-fnt-1);
        --clr-header-a: var(--clr-fnt-1);
        
        --clr-bdy-bg: var(--clr-bg-3);
        --clr-bdy-fnt: #fefefe;

        
        --clr-accent: var(--systemBlue);    
        accent-color: var(--clr-accent);

        --clr-input-bdr: #5299c3;
        --clr-input-fcs: hsla(218, 89%, 50%, 1.0);
        --clr-input: hsla(218, 89%, 50%, .9);
        --clr-input-bg: var(--systemGray5);
        --clr-input-fnt: var(--clr-fnt-1);

        --clr-accent-fcs: hsla(218, 89%, 50%, 1.0);
    }
}