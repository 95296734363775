:root {
    --icon-width        : 25px;
    --icon-height       : 25px;        
  
    --icon-width-min    : 25px;
    --icon-height-min   : 25px;
    --icon-width-max    : 100px;
    --icon-height-max   : 100px;
    --icon-bg-clr       : var(--clr-accent);
    --icon-svg-clr      : #000;
    --icon-bs           : 0px 0px 1px 1px rgba(0,0,0,.1);
    --icon-bs-hover     : 0px 0px 10px 0px rgba(0,0,0,.6);
    --icon-stk-clr     : none;
}

.icon__container{
    
    display: flex;
    align-items: center;
    
    min-width: var(--icon-width-min);
    min-height: var(--icon-height-min);
    max-width: var(--icon-width-max);
    max-height: var(--icon-height-max);
    width:  var(--icon-width);
    height: var(--icon-height);
    
    
    /* margin: 0 .5rem; */
    border-radius: 25%;
    padding: .25rem;
    
    background-color: var(--icon-bg-clr);
    fill: var(--icon-svg-clr);
    
    backface-visibility: hidden;
    /* transform: translateZ(0); */
    /* opacity: 0; */
    /* transition: ease-in-out 200ms; */
    /* animation: bounce 500ms ease; */
}

.icon__isLoaded{
  /*TODO:: 091122 #EP || Add this */
  opacity: 1;
  animation: bounce 100ms ease;
}

.navigation__aside .icon__container{
  /* Only add border shadow if aside */
    box-shadow: var(--icon-bs);
}

.icon__container:hover {
    /* filter: brightness(1.05); */
    /* width: 35px;
    height: 35px; */
    
    box-shadow: var(--icon-bs-hover);
}


.icon__noBoxShadow,
.icon__noBoxShadow:hover,
.clear__button .icon__container:hover,
.header__brandName .icon__container:hover,
footer .icon__container:hover,
.search__button .icon__container:hover,
.header__menu .icon__container:hover 
{ /* Remove styling for header as in the header it's controlled within the Navigation styling  */
    box-shadow: none !important;
    border: none !important;
}

.icon__container[mobile='true']{
  display: none !important;
}


.icon__forceStroke * {
  /* Used to force stroke DOWN to all children in Alerts and PSI*/
  stroke: var(--clr-accent);
}


.icon__alerts {
  /* Updating Icon for custom size in Alerts */
  display: flex;
  align-content: center;
  padding-right: .5rem;
}

.icon__alerts .icon__container {
  /* Making Icon Bigger and less padding in Alerts */
  margin: auto .25rem !important;
  padding: 0 !important;

}

/*----------------------------------------------------------------------------*/
@media screen AND (max-width: 600px){
  
  /* .navigation__aside .navigation__link,
  .navigation__aside .navigation__link:first-child,
  .navigation__aside .navigation__link:last-child
  {
    background-color: none;
    box-shadow: none;
    border: none;
    fill: none;
    stroke: none;
  } */

  .icon__noBoxShadow,
  .navigation__aside .navigation__link{
    /* Removing borders and styling within aside nav in mobile */
    background-color: transparent !important;
    background: transparent !important;
    border: 2px solid transparent !important;
    box-shadow: none !important;
    box-shadow: none !important;
  }

  .icon__noBoxShadow:hover,
  .navigation__aside .icon__container:hover {
    /* Remove box shadow behavior to minify */
    /* filter: brightness(1.05); */
    box-shadow: none !important;
  }
  

  .navigation__aside .link__active,
  .navigation__aside .link__active .navigation__icon svg{
    /* If active link make brand-blue! */
    /* color: var(--clr-fnt-700) !important;  */
    /* color: var(--clr-fnt-700) !important; */
    color: var(--icon-bg-clr) !important;
    fill: var(--icon-bg-clr) !important;
  }

  .icon__noBoxShadow,
  .navigation__aside .icon__container{
    /*Remove background  on Icons within aside anv in mobile*/
    background: none !important; /*Note: !important required to override inline style with JSX*/
    border: none !important;
    box-shadow: none !important;
  }

  .navigation__aside .icon__container svg{
    /* Update SVG fill to be brand color */
    fill: var(--clr-fnt-500) !important;
  }

  .navigation__aside .navigation__link:hover .icon__container svg{
    fill: var(--clr-fnt-700) !important;
  }

  .navigation__aside .navigation__link:hover .icon__container svg{
    filter: brightness(1.05);
  }

  .navigation__aside .icon__container svg polygon,
  .navigation__aside .icon__container svg polyline,
  .navigation__aside .icon__container svg line{
    /* All values within the Chart/Dashboard icon specifically  */
    stroke: var(--clr-fnt-500) !important;
  }
  .navigation__aside .navigation__link:hover .icon__container svg polygon,
  .navigation__aside .navigation__link:hover .icon__container svg polyline,
  .navigation__aside .navigation__link:hover .icon__container svg line{
    stroke: var(--clr-fnt-700) !important;
  }


  .navigation__aside .navigation__link svg{
    /* fill: var(--clr-fnt-700) !important; */
  }

  .navigation__aside .link__active:hover .navigation__icon .icon__container svg {
    filter: brightness(1.05);
    fill: var(--clr-accent) !important;
    
  }
  
  .navigation__aside .link__active .icon__container svg polygon,
  .navigation__aside .link__active .icon__container svg polyline,
  .navigation__aside .link__active .icon__container svg line {
    stroke: var(--clr-accent) !important;
    transition: ease-in-out 100ms;
  }
  .navigation__aside .navigation__link.link__active:hover  .icon__container svg polygon,
  .navigation__aside .navigation__link.link__active:hover  .icon__container svg polyline,
  .navigation__aside .navigation__link.link__active:hover  .icon__container svg line{
    stroke: var(--clr-accent) !important;
  }


}

/* .icon__account {

}

.icon__compressor-bw
{
    enable-background: new 0 0 512 512;
} */

/* .icon__account,
.icon__gear
{
    transition: ease-in-out .2s;
    filter: invert(90%);
    color: white;
} */


/* LOADING TRIANGLE COLOR AND ANIMATION KEYFRAME */
.loader-triangle-3-sides polygon {
  stroke: #ffeb3b;
  stroke: #fff;
  stroke-dasharray: 17;
  animation:  anm-tr-3-move 2.5s
              cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
}

@keyframes anm-tr-3-move {
  to {
      stroke-dashoffset: -136;
  }
}


.icon__info_bordered{
  
  padding: 0;
  margin: 0;
}